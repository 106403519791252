import { Fragment } from 'react';
import { useDelete, useNotify, useRecordContext, useRefresh } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

import { analyticsTrack } from '@pumpkincare/analytics';

import { MGA_DOCUMENT_TYPES } from '../../../../constants/claimAttachmentDocumentTypes';
import useBooleanInput from '../../../../hooks/useBooleanInput';
import RoutePaths from '../../../../routes';

function DeleteClaimAttachmentButton({
  invoiceRecord,
  currentOpsUserData,
  disableButton = false,
  color = '#B00020',
  isPetView = false,
}) {
  const record = invoiceRecord || useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [deleteOne, { isLoading }] = useDelete();
  const [isModalOpen, toggleModalOpen] = useBooleanInput(false);

  function deleteAttachment(attachment) {
    if (
      (attachment.type &&
        MGA_DOCUMENT_TYPES[attachment.type] === MGA_DOCUMENT_TYPES.claim_invoice) ||
      attachment.hasOwnProperty('line_items')
    ) {
      analyticsTrack({
        event: 'clicked_delete_invoice_claims',
        label: 'claims delete invoice',
        category: 'claims admin actions',
        ops_user_id: currentOpsUserData?.id,
        claim_id: record.claim_id || disableButton,
        file_name: record?.source_file_name,
      });
    }
    const payload = { id: attachment.id };
    if (isPetView) {
      payload['deleted_from_pets_view'] = true;
    }
    deleteOne(
      RoutePaths.documentsV2,
      {
        id: attachment.id,
        meta: {
          data: payload,
        },
      },
      {
        onSuccess: () => {
          refresh();
        },
        onError: error => {
          notify(
            `There was an error while deleting the document: ${error.message}`,
            {
              type: 'error',
            }
          );
        },
      }
    );
  }

  function handleConfirmDelete() {
    deleteAttachment(record);
    toggleModalOpen();
  }

  return (
    <Fragment>
      <IconButton
        sx={{ padding: '6px', color: color }}
        onClick={e => {
          e.stopPropagation();
          toggleModalOpen();
        }}
        disabled={isLoading || disableButton}
      >
        <DeleteIcon style={{ width: '20px' }} />
      </IconButton>

      <Dialog
        open={isModalOpen}
        onClose={toggleModalOpen}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'></DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this file?
            <p style={{ color: '#2A41DE', fontWeight: 'bold' }}>
              {record.source_file_name}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              e.stopPropagation();
              toggleModalOpen();
            }}
            style={{ color: '#D4424E' }}
            autoFocus
          >
            CANCEL
          </Button>
          <Button
            onClick={e => {
              e.stopPropagation();
              handleConfirmDelete();
            }}
            style={{ color: '#3EC28F' }}
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

DeleteClaimAttachmentButton.propTypes = {
  invoiceRecord: PropTypes.object,
  currentOpsUserData: PropTypes.object,
  disableButton: PropTypes.bool,
  color: PropTypes.string,
  isPetView: PropTypes.bool,
};

export default DeleteClaimAttachmentButton;
