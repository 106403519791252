import { Typography } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { auditLogStyle } from './audit-log-style.js';
function AuditLog({ auditLog, classes: propClasses }) {
  const classes = auditLogStyle();

  return (
    <div className={classNames(classes.root, propClasses.root)}>
      {auditLog.map((auditItem, index) => {
        const isLastItem = index + 1 === auditLog.length;

        return (
          <div key={index} className={classNames(classes.item, propClasses.item)}>
            {!isLastItem ? (
              <div
                data-testid='audit-dash-line'
                className={classNames(classes.dashLine, propClasses.dashLine)}
              />
            ) : null}

            <div style={{ display: 'flex' }}>
              <div
                data-testid='audit-circle'
                className={classNames(classes.circle, propClasses.circle, {
                  [classes.lastCircle]: isLastItem,
                })}
              />
              <Typography className={classNames(classes.event, propClasses.event)}>
                {auditItem.event}
              </Typography>
              {auditItem.gladly ? (
                <Typography className={classes.endpoint}>
                  <span className={classes.parentheses}>{'('}</span>
                  <a
                    href={auditItem.gladly}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    Gladly Link
                  </a>
                  <span className={classes.parentheses}>{')'}</span>
                </Typography>
              ) : null}
            </div>

            {auditItem.description ? (
              <Typography className={classNames(classes.text, propClasses.text)}>
                {auditItem.description}
              </Typography>
            ) : null}

            {auditItem.detail ? (
              <Typography className={classNames(classes.text, propClasses.text)}>
                {auditItem.detail}
              </Typography>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

AuditLog.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    item: PropTypes.string,
    dashLine: PropTypes.string,
    circle: PropTypes.string,
    event: PropTypes.string,
    text: PropTypes.string,
  }),

  auditLog: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.string.isRequired,
      description: PropTypes.string,
      detail: PropTypes.string,
    })
  ).isRequired,
};

AuditLog.defaultProps = {
  classes: {},
};

export default AuditLog;
