import { FormDataConsumer } from 'react-admin';
import { MenuItem, Select, TextField, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import VetInput from '../../../../shared/components/VetInput';
import {
  HDYHAU_OPTIONS,
  HDYHAU_OPTIONS_LEGACY,
  MY_VET_CLINIC,
  OTHER,
} from '../constants/HDYHAUQuestions';

function ReferralForm({
  HDYHAUAnswer,
  onHDYHAUChange,
  onHDYHAUVetChange,
  onHDYHAUOtherChange,
  quote,
}) {
  const { mvx218MultiTouchHdyhau } = useFlags();
  const options = mvx218MultiTouchHdyhau ? HDYHAU_OPTIONS : HDYHAU_OPTIONS_LEGACY;

  const hdyhauOptions = options.map(option => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

  return (
    <div>
      <Typography
        style={{
          margin: '60px 0 0',
          fontSize: '20px',
          lineHeight: '24px',
          fontWeight: '500',
        }}
      >
        Referral
      </Typography>
      <Typography style={{ margin: '24px 0' }}>
        How did you hear about Pumpkin?<sup style={{ color: '#B00020' }}>*</sup>
      </Typography>
      <Select
        onChange={onHDYHAUChange}
        fullWidth={true}
        defaultValue=''
        style={{ marginBottom: '24px' }}
      >
        {hdyhauOptions}
      </Select>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <>
              {HDYHAUAnswer === MY_VET_CLINIC ? (
                <VetInput
                  formData={{ ...formData, vet_hdyhau: quote.vet }}
                  onChange={onHDYHAUVetChange}
                  source='vet_hdyhau'
                  zipcodeSource={'policy_zipcode'}
                />
              ) : null}

              {mvx218MultiTouchHdyhau && HDYHAUAnswer === OTHER ? (
                <TextField
                  label='Other'
                  variant='outlined'
                  sx={{ width: '100%' }}
                  onChange={e => onHDYHAUOtherChange(e.target.value)}
                />
              ) : null}
            </>
          );
        }}
      </FormDataConsumer>
    </div>
  );
}

ReferralForm.propTypes = {
  HDYHAUAnswer: PropTypes.string,
  onHDYHAUChange: PropTypes.func,
  onHDYHAUOtherChange: PropTypes.func,
  onHDYHAUVetChange: PropTypes.func,
  quote: PropTypes.shape({
    vet: PropTypes.object,
  }),
};

export default ReferralForm;
