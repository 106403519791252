import { useGetList, useGetOne } from 'react-admin';

import RoutePaths from '../../routes';
import { sortPoliciesByStatusASC } from './user-utils';

export const PET_QUERY = 'pets';

export function usePet(petId, options = {}) {
  return useGetOne(PET_QUERY, { id: petId }, { enabled: !!petId, ...options });
}

export function usePetByUser(userId, options = {}) {
  return useGetList(
    PET_QUERY,
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: { user_id: userId },
    },
    {
      select: response => {
        response.data = sortPoliciesByStatusASC(response);
        return response;
      },
      ...options,
    }
  );
}

export function usePetAvailablePlans(petId) {
  return useGetOne(
    PET_QUERY,
    { id: `${petId}/available-plans` },
    { enabled: !!petId }
  );
}

export function usePetAvailableWellnessPlans(petId) {
  return useGetOne(
    PET_QUERY,
    { id: `${petId}/available-wellness-plans` },
    { enabled: !!petId }
  );
}

export function usePetPolicies(petId) {
  return useGetOne(
    `${RoutePaths.quotes}/${PET_QUERY}`,
    { id: `${petId}/policy-options` },
    { enabled: !!petId }
  );
}

export function usePetMrrHistory(petId, options = { enabled: true }) {
  return useGetOne(
    PET_QUERY,
    { id: `${petId}/mrr-history` },
    {
      ...options,
      enabled: !!petId,
    }
  );
}
