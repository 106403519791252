import { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Card, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { formatISODate } from '../../../../shared/utils';
import { PetMrrStateEnum } from '../../constants';
import MedicalRecordsReviewHistoryModal from '../medical-records-review-history-modal';
import { medicalRequestRecordsInfoBannerStyle } from './medical-request-records-info-banner-style';

function getAttributedByState(state) {
  let title;
  let icon;
  let color;
  let backgroundColor;
  let border;
  switch (state) {
    case PetMrrStateEnum.NEEDS_REVIEW.id:
      title = 'Needs Review';
      icon = <WarningAmberIcon fontSize='medium' style={{ color: '#EF6C00' }} />;
      color = '#663C00';
      backgroundColor = '#FFF4E5';
      break;
    case PetMrrStateEnum.PARTIAL_HISTORY_REVIEWED.id:
      title = 'Partial History Reviewed';
      icon = <InfoOutlinedIcon fontSize='medium' style={{ color: '#0288D1' }} />;
      color = '#373F51';
      backgroundColor = '#EEF0FE';
      break;
    case PetMrrStateEnum.FULL_HISTORY_REVIEWED.id:
      title = 'Full History Reviewed';
      color = '#1E4620';
      backgroundColor = '#EDF7ED';
      icon = (
        <CheckCircleOutlineIcon fontSize='medium' style={{ color: '#2E7D32' }} />
      );
      break;
    case PetMrrStateEnum.MR_REVIEW_NOT_NEEDED.id:
      title = 'MR Review Not Needed';
      color = '#1E4620';
      backgroundColor = '#EDF7ED';
      icon = (
        <CheckCircleOutlineIcon fontSize='medium' style={{ color: '#2E7D32' }} />
      );
      break;
    default:
      title = 'Unknown or No Review';
      icon = <InfoOutlinedIcon fontSize='medium' style={{ color: '#0288D1' }} />;
      color = '#373F51';
      backgroundColor = 'transparent';
      border = '1px solid #0288D1';
  }
  return { title, icon, color, backgroundColor, border };
}

function MedicalRequestRecordsInfoBanner({
  state,
  date,
  initiator,
  currentOpsUserId,
  petId,
  onMrrHistoryUpdate,
  claimSubmissionId = '',
}) {
  const classes = medicalRequestRecordsInfoBannerStyle();
  const { title, icon, color, backgroundColor, border } =
    getAttributedByState(state);
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {openModal ? (
        <MedicalRecordsReviewHistoryModal
          currentState={state}
          currentOpsUserId={currentOpsUserId}
          petId={petId}
          onClose={() => setOpenModal(false)}
          onSuccess={onMrrHistoryUpdate}
          claimSubmissionId={claimSubmissionId}
        />
      ) : null}
      <Card
        className={classes.card}
        style={{ backgroundColor, border: border || null }}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.infoContainer}>
            {icon}
            <Typography className={classes.content}>
              <span className={classes.bannerTitle} style={{ color }}>
                {title}
              </span>
              {initiator && date ? (
                <span
                  className={classes.bannerDescription}
                  style={{ color }}
                >{`History last updated ${formatISODate(date, {
                  isGenerated: true,
                })}, ${initiator}`}</span>
              ) : null}
            </Typography>
          </div>
          <Button
            color='primary'
            variant='contained'
            className={classes.logUpdateButton}
            style={{ color }}
            disableRipple
            onClick={() => setOpenModal(true)}
          >
            LOG/UPDATE
          </Button>
        </CardContent>
      </Card>
    </>
  );
}

MedicalRequestRecordsInfoBanner.propTypes = {
  state: PropTypes.string,
  date: PropTypes.string,
  initiator: PropTypes.string,
  currentOpsUserId: PropTypes.string,
  petId: PropTypes.string,
  onMrrHistoryUpdate: PropTypes.func,
  claimSubmissionId: PropTypes.string,
};

export default MedicalRequestRecordsInfoBanner;
