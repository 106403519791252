import { useState } from 'react';
import { Form, TextInput } from 'react-admin';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { claimPumpkinNowDecisionStyle } from './claim-pumpkin-now-decision-style';

const REASONS_TO_DEFER = [
  { id: 'mrr_required', name: 'MRR required' },
  { id: 'another_open_claim', name: 'Another open claim' },
  { id: 'invoice_less_than_1k', name: 'Invoice less than 1K' },
  { id: 'wellness_only_claim', name: 'Wellness only claim' },
  { id: 'multiple_visits', name: 'Multiple invoices/separate visits' },
  { id: 'other', name: 'Other (custom field)' },
];

export const PUMPKIN_NOW_STATUSES = {
  PENDING: 'PENDING',
  EXPEDITED: 'EXPEDITED',
  DEFERRED: 'DEFERRED',
};

function ClaimPumpkinNowDecision({ onSubmit = () => {}, isSubmitting = false }) {
  const classes = claimPumpkinNowDecisionStyle();
  const [actionBtnValue, setActionBtnValue] = useState(null);
  const [deferralReason, setDeferralReason] = useState('');
  const [notes, setNotes] = useState('');

  const isConfirmButtonDisabled = !(
    actionBtnValue === PUMPKIN_NOW_STATUSES.EXPEDITED ||
    (actionBtnValue === PUMPKIN_NOW_STATUSES.DEFERRED &&
      deferralReason &&
      (deferralReason !== 'other' || (deferralReason === 'other' && !!notes)))
  );

  return (
    <Form onSubmit={() => onSubmit(actionBtnValue, deferralReason, notes)}>
      <Typography component='div' role='tabpanel' className={classes.contentWrapper}>
        <div className={classes.pumpkinNowTitle}>Pumpkin Now Decision</div>

        <Grid container spacing={2}>
          <Grid item>
            <ToggleButtonGroup
              value={actionBtnValue}
              exclusive
              onChange={event => {
                setActionBtnValue(event.target.value);
              }}
              labelId='reimbursement-rate'
              size='large'
              className={classes.buttonGroup}
            >
              <ToggleButton
                className={classes.toggleButton}
                value={PUMPKIN_NOW_STATUSES.EXPEDITED}
              >
                EXPEDITE
              </ToggleButton>
              <ToggleButton
                className={classes.toggleDeferButton}
                value={PUMPKIN_NOW_STATUSES.DEFERRED}
              >
                DEFER
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item xs={3}>
            {actionBtnValue == PUMPKIN_NOW_STATUSES.DEFERRED ? (
              <FormControl
                fullWidth
                variant='outlined'
                margin='normal'
                className={classes.deferralSelect}
              >
                <InputLabel id='status-label'>Deferral Reason</InputLabel>
                <Select
                  labelId='status-label'
                  value={deferralReason}
                  onChange={e => setDeferralReason(e.target.value)}
                  label='Deferral Reason'
                >
                  {REASONS_TO_DEFER.map(reason => (
                    <MenuItem key={reason.id} value={reason.id}>
                      {reason.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>

          <Grid item xs={6}>
            {actionBtnValue == PUMPKIN_NOW_STATUSES.DEFERRED ? (
              <TextInput
                multiline
                label='Notes for deferral'
                source='notes'
                minRows={1}
                fullWidth
                variant='outlined'
                className={classes.notesTextInput}
                onChange={e => setNotes(e.target.value)}
              />
            ) : null}
          </Grid>

          <Grid
            item
            xs={1}
            className={isSubmitting ? classes.buttonContainer : null}
          >
            {isSubmitting ? (
              <CircularProgress size={30} />
            ) : (
              <Button
                variant='contained'
                color='primary'
                size='large'
                disabled={isConfirmButtonDisabled}
                className={classes.confirmButton}
                type='submit'
              >
                Confirm
              </Button>
            )}
          </Grid>
        </Grid>
      </Typography>
    </Form>
  );
}

ClaimPumpkinNowDecision.propTypes = {
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ClaimPumpkinNowDecision;
