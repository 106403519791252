import makeStyles from '@mui/styles/makeStyles';

const auditLogStyle = makeStyles(
  {
    root: {
      position: 'relative',
    },

    item: {
      borderRadius: '5px',
      position: 'relative',
      zIndex: '2',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px 0',
      '&:not(:last-child)': { paddingBottom: '24px' },
    },

    dashLine: {
      position: 'absolute',
      zIndex: '1',
      top: '16px',
      bottom: '0',
      left: '7px',
      height: '100%',
      borderLeft: '1px dashed #949494',
    },

    circle: {
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      marginRight: '12px',
      backgroundColor: '#CDCFD3',
    },
    lastCircle: {
      backgroundColor: '#5368F5',
    },

    event: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      color: '#000000',
    },

    text: {
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#767676',
      // marginLeft: circleWidth + circleMarginRight,
      marginLeft: '28px',
    },

    endpoint: {
      display: 'flex',
      marginLeft: '10px',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '16px',
      textTransform: 'uppercase',
      color: '#2A41DE',
      letterSpacing: '1.5px',
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },

    parentheses: {
      fontSize: '10px',
      color: '#000000',
    },
  },
  { name: 'audit-log' }
);

export { auditLogStyle };
