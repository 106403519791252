import makeStyles from '@mui/styles/makeStyles';

const BUTTON = {
  borderRadius: '4px',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '1.25px',
  textTransform: 'uppercase',
  border: '1px solid #CDCFD3',
  padding: '10px',
};

const medicalRecordsReviewHistoryStyle = makeStyles(
  {
    paper: { maxWidth: '470px', width: '80vw' },
    modalTitle: {
      padding: '7px 30px',
      color: '#373F51',
      marginTop: '10px',
    },
    modalContent: {
      padding: '0 32px',
    },
    textLabel: {
      margin: 0,
      width: '100%',
      alignItems: 'flex-start',
      '& span': { fontSize: '14px' },
      '& > div > div': { background: 'white' }, // input only
    },
    modalActions: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '0 16px',
      padding: '32px',
    },
    unsetSpacing: {
      '& > :not(:first-child)': { marginLeft: '0' },
    },
    cancelButton: { ...BUTTON },
    confirmButton: {
      ...BUTTON,
      color: 'white',
      backgroundColor: '#5368F5',
      borderColor: '#5368F5',
      '&:disabled': {
        borderColor: '#CDCFD3',
        backgroundColor: '#CDCFD3',
        color: 'white',
      },
    },
  },
  { name: 'medical-records-request-history' }
);

export { medicalRecordsReviewHistoryStyle };
