import { useEffect, useState } from 'react';
import {
  DateInput,
  FormDataConsumer,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useCreate,
  useDataProvider,
  useNotify,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';

import { CustomBadge } from '@pumpkincare/shared/ui';

import { mgaCancellationReasons } from '../../../constants/cancellationReasons';
import { PRODUCT_STATUS_COLORS } from '../../../constants/product-status-colors';
import RoutePaths from '../../../routes';
import { formatCurrency, getDateAsFormattedUTC } from '../../../shared/utils';
import {
  CANCELLATION_TYPES,
  cancellationTypesFor,
  isCancelPlan,
  isCancelPolicy,
  isCancelWellness,
  productsFor,
} from '../utils/cancellation-utils';

const today = getDateAsFormattedUTC(new Date());

function CustomToolbar(props) {
  return (
    <Toolbar
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        background: 'none',
      }}
      {...props}
    >
      <SaveButton
        label='Add Cancellation'
        color='secondary'
        variant='outlined'
        icon={<AddIcon />}
      />
    </Toolbar>
  );
}

function RefundAmount({ type, petData }) {
  const { punks1925AdjustWellnessRefundAndObLogic } = useFlags();

  function getText() {
    if (petData && 'amount' in petData) {
      return formatCurrency(Math.abs(petData.amount));
    }
  }

  // TO-DO: After removing the punks1925AdjustWellnessRefundAndObLogic FF
  // Combine the functions below into the prevent one, adjusting the strings
  function getWellnessLabel() {
    if (punks1925AdjustWellnessRefundAndObLogic) {
      if (petData && 'amount' in petData) {
        return petData.amount < 0
          ? 'Wellness Refund Amount'
          : 'Wellness Outstanding Balance Amount';
      } else {
        return 'Wellness Amount';
      }
    } else {
      return 'Wellness Refund Amount';
    }
  }

  function getLabel() {
    if (type === 'policy') {
      return 'Insurance Refund Amount';
    }

    if (type === 'wellness') {
      return getWellnessLabel();
    }

    if (petData && 'amount' in petData) {
      return petData.amount < 0
        ? 'Prevent Refund Amount'
        : 'Prevent Outstanding Balance Amount';
    } else {
      return 'Prevent Amount';
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '16px',
      }}
    >
      <Typography
        style={{ fontSize: '16px', fontWeight: '500', textTransform: 'capitalize' }}
      >
        {getLabel()}:
      </Typography>
      <Typography style={{ fontSize: '16px', fontWeight: '400' }}>
        {getText()}
      </Typography>
    </div>
  );
}

function CancellationForm({
  isLoading = false,
  pets = [],
  onAddItem = () => {},
  cancellationsInProgress = false,
  cancellationsInProgressList = false,
}) {
  const [eligiblePets, setEligiblePets] = useState([]);
  const [petData, setPetData] = useState({});
  const [create] = useCreate();
  const [showPolBadge, setShowPolBadge] = useState(false);
  const [showPlanBadge, setShowPlanBadge] = useState(false);
  const [showWellnessBadge, setShowWellnessBadge] = useState(false);
  const [polStatus, setPolStatus] = useState(null);
  const [planStatus, setPlanStatus] = useState(null);
  const [wellnessStatus, setWellnessStatus] = useState(null);
  const dataProvider = useDataProvider();

  const notify = useNotify();

  const [policyRefundAmount, setPolicyRefundAmount] = useState(null);
  const [planRefundAmount, setPlanRefundAmount] = useState(null);
  const [wellnessRefundAmount, setWellnessRefundAmount] = useState(null);

  useEffect(() => {
    setEligiblePets(pets.filter(pet => !cancellationsInProgress.includes(pet.id)));
  }, [pets, cancellationsInProgress]);

  function handleOnSave(data) {
    const pet = pets.find(pet => pet.id === data.pet_id);

    const reason = data.reason
      ? mgaCancellationReasons.find(r => r.id === data.reason).name
      : null;

    const cancelPolicy = isCancelPolicy(data.cancellation_type);
    const cancelPlan = isCancelPlan(data.cancellation_type);
    const cancelWellness = isCancelWellness(data.cancellation_type);

    const policy = cancelPolicy
      ? pet.policies.find(policy => policy.id === data.policy_id)
      : null;

    const plan = cancelPlan
      ? pet.plans.find(plan => plan.id === data.plan_id).name
      : null;

    const wellness = cancelWellness
      ? pet.wellness.find(wellness => wellness.id === data.wellness_id)
      : null;

    const payload = {
      pet_id: pet.id,
      pet_name: pet.name,
      cancel_policy: cancelPolicy,
      cancel_plan: cancelPlan,
      cancel_wellness: cancelWellness,
      cancellation_type: data.cancellation_type,
      end_date: data.end_date,
      cancellation_requested_date: data.cancellation_requested_date,
      reason: reason,
      policy: policy?.name,
      policy_id: policy?.id,
      plan: plan,
      wellness: wellness?.name,
      insurance_refund:
        policyRefundAmount && cancelPolicy ? policyRefundAmount * 100 : null, // cents
      prevent_refund: planRefundAmount && cancelPlan ? planRefundAmount * 100 : null, // cents
      wellness_refund:
        wellnessRefundAmount && cancelWellness ? wellnessRefundAmount * 100 : null, // cents
    };

    onAddItem(payload);
  }

  if (isLoading) {
    return (
      <Typography style={{ padding: '16px', color: 'rgba(0, 0, 0, 0.6)' }}>
        Loading...
      </Typography>
    );
  }

  if (eligiblePets.length === 0) {
    return (
      <Typography style={{ padding: '16px', color: 'rgba(0, 0, 0, 0.6)' }}>
        No pets found
      </Typography>
    );
  }

  function handlePlanChange(value, formData) {
    const plans = productsFor(pets, formData.pet_id, 'plans');
    const plan = plans.find(p => p.id === value);

    if (plan?.plan_status) {
      setShowPlanBadge(true);
      setPlanStatus({
        bg: PRODUCT_STATUS_COLORS[plan?.plan_status]?.color,
        name: PRODUCT_STATUS_COLORS[plan?.plan_status]?.name,
        color: PRODUCT_STATUS_COLORS[plan?.plan_status]?.textColor,
      });
    }

    create(
      `${RoutePaths.petPlans}/${value}/refund-amount`,
      {
        data: {
          end_date: formData.end_date || null,
        },
        meta: { returnJson: 'DATA' },
      },
      {
        onSuccess: data => {
          if (data && 'amount' in data) {
            setPetData(current => ({ ...current, planRefundData: data }));
            setPlanRefundAmount(data.amount);
          }
        },
        onError: ({ message }) => {
          notify(
            message || `There was an error while getting plan refund amount`,
            'error'
          );
        },
      }
    );
  }

  function handlePolicyChange(value, formData) {
    const policies = productsFor(pets, formData.pet_id, 'policies');
    const policy = policies.find(p => p.id === value);
    const resource = `${RoutePaths.policies}/${value}/refund-amount`;
    const data = {
      end_date: formData.end_date || null,
      pet_policies: cancellationsInProgressList
        ? cancellationsInProgressList.map(pet => ({
            pet_id: pet.pet_id,
            policy_id: pet.policy_id,
          }))
        : [],
    };

    if (policy?.policy_status) {
      setShowPolBadge(true);
      setPolStatus({
        bg: PRODUCT_STATUS_COLORS[policy?.policy_status]?.color,
        name: PRODUCT_STATUS_COLORS[policy?.policy_status]?.name,
        color: PRODUCT_STATUS_COLORS[policy?.policy_status]?.textColor,
      });
    }

    dataProvider
      .create(resource, {
        data,
        returnJson: 'DATA',
      })
      .then(({ data }) => {
        if (data && 'amount' in data) {
          setPetData(current => ({ ...current, policyRefundData: data }));
          setPolicyRefundAmount(data.amount);
        }
      })
      .catch(({ message }) => {
        notify(
          message || `There was an error while getting policy refund amount`,
          'error'
        );
      });
  }

  function handleWellnessChange(value, formData) {
    const wellnesses = productsFor(pets, formData.pet_id, 'wellness');
    const wellness = wellnesses.find(p => p.id === value);

    if (wellness?.status) {
      setShowWellnessBadge(true);
      setWellnessStatus({
        bg: PRODUCT_STATUS_COLORS[wellness?.status]?.color,
        name: PRODUCT_STATUS_COLORS[wellness?.status]?.name,
        color: PRODUCT_STATUS_COLORS[wellness?.status]?.textColor,
      });
    }

    create(
      `${RoutePaths.wellness}/${value}/refund-amount`,
      {
        data: {
          end_date: formData.end_date || null,
          pet_wellness: cancellationsInProgressList
            ? cancellationsInProgressList.map(pet => ({
                pet_id: pet.pet_id,
                wellness_id: pet.wellness_id,
              }))
            : [],
        },
        meta: { returnJson: 'DATA' },
      },
      {
        onSuccess: data => {
          if (data && 'amount' in data) {
            setPetData(current => ({ ...current, wellnessRefundData: data }));
            setWellnessRefundAmount(data.amount);
          }
        },
        onError: ({ message }) => {
          notify(
            message || `There was an error while getting wellness refund amount`,
            'error'
          );
        },
      }
    );
  }

  function handleEndDateChange(value, formData) {
    if (!value) {
      return;
    }

    if (isCancelPolicy(formData.cancellation_type)) {
      handlePolicyChange(formData.policy_id, { ...formData, end_date: value });
    }

    if (isCancelPlan(formData.cancellation_type) && formData.plan_id) {
      handlePlanChange(formData.plan_id, { ...formData, end_date: value });
    }

    if (isCancelWellness(formData.cancellation_type) && formData.wellness_id) {
      handleWellnessChange(formData.wellness_id, { ...formData, end_date: value });
    }
  }

  function showPolicySelect(cancellationType) {
    return [
      CANCELLATION_TYPES.insuranceOnly.id,
      CANCELLATION_TYPES.insuranceAndPrevent.id,
      CANCELLATION_TYPES.insuranceAndWellness.id,
    ].includes(cancellationType);
  }

  function showPlanSelect(cancellationType) {
    return [
      CANCELLATION_TYPES.preventOnly.id,
      CANCELLATION_TYPES.insuranceAndPrevent.id,
    ].includes(cancellationType);
  }

  function showWellnessSelect(cancellationType) {
    return [
      CANCELLATION_TYPES.insuranceAndWellness.id,
      CANCELLATION_TYPES.wellnessOnly.id,
    ].includes(cancellationType);
  }

  return (
    <SimpleForm
      onSubmit={handleOnSave}
      toolbar={<CustomToolbar />}
      defaultValues={{}}
      key={stringify(cancellationsInProgress)}
    >
      <DateInput
        label='Requested Date'
        source='cancellation_requested_date'
        validate={[required()]}
        defaultValue={today}
        fullWidth
        inputProps={{
          max: today,
        }}
      />

      <SelectInput
        label='Pet'
        source='pet_id'
        choices={eligiblePets}
        validate={required()}
        fullWidth
        onChange={() => {
          setShowPolBadge(false);
          setShowPlanBadge(false);
        }}
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.pet_id ? (
            <SelectInput
              label='Cancellation Type'
              source='cancellation_type'
              choices={cancellationTypesFor(pets, formData.pet_id)}
              validate={required()}
              fullWidth
            />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          formData.cancellation_type ? (
            <>
              {showPolicySelect(formData.cancellation_type) ? (
                <SelectInput
                  label={
                    <CustomBadge
                      bg={polStatus?.bg}
                      badgeContent={polStatus?.name}
                      color={polStatus?.color}
                      show={showPolBadge}
                    >
                      Current Policy
                    </CustomBadge>
                  }
                  source='policy_id'
                  choices={productsFor(pets, formData.pet_id, 'policies')}
                  validate={required()}
                  onChange={event =>
                    handlePolicyChange(event.target.value, formData)
                  }
                  fullWidth
                />
              ) : null}

              {showWellnessSelect(formData.cancellation_type) ? (
                <SelectInput
                  label={
                    <CustomBadge
                      bg={wellnessStatus?.bg}
                      badgeContent={wellnessStatus?.name}
                      color={wellnessStatus?.color}
                      show={showWellnessBadge}
                    >
                      Current Wellness
                    </CustomBadge>
                  }
                  source='wellness_id'
                  choices={productsFor(pets, formData.pet_id, 'wellness')}
                  validate={required()}
                  onChange={event =>
                    handleWellnessChange(event.target.value, formData)
                  }
                  fullWidth
                />
              ) : null}

              {showPlanSelect(formData.cancellation_type) ? (
                <SelectInput
                  label={
                    <CustomBadge
                      bg={planStatus?.bg}
                      badgeContent={planStatus?.name}
                      color={planStatus?.color}
                      show={showPlanBadge}
                    >
                      Plan
                    </CustomBadge>
                  }
                  source='plan_id'
                  choices={productsFor(pets, formData.pet_id, 'plans')}
                  validate={required()}
                  onChange={event => handlePlanChange(event.target.value, formData)}
                  fullWidth
                />
              ) : null}
            </>
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          formData.pet_id ? (
            <DateInput
              label='Coverage End Date'
              source='end_date'
              validate={[required()]}
              onChange={event => handleEndDateChange(event.target.value, formData)}
              fullWidth
              inputProps={{
                max: today,
              }}
            />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          formData.pet_id ? (
            <SelectInput
              label='Cancellation Reason'
              source='reason'
              choices={mgaCancellationReasons}
              fullWidth
              validate={required()}
            />
          ) : null
        }
      </FormDataConsumer>

      <Typography style={{ fontSize: '20px', fontWeight: '500', marginTop: '24px' }}>
        Summary
      </Typography>

      <FormDataConsumer>
        {({ formData }) =>
          isCancelPolicy(formData.cancellation_type) && formData.policy_id ? (
            <RefundAmount type={'policy'} petData={petData.policyRefundData} />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          isCancelPlan(formData.cancellation_type) && formData.plan_id ? (
            <RefundAmount type={'plan'} petData={petData.planRefundData} />
          ) : null
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          isCancelWellness(formData.cancellation_type) && formData.wellness_id ? (
            <RefundAmount type={'wellness'} petData={petData.wellnessRefundData} />
          ) : null
        }
      </FormDataConsumer>
    </SimpleForm>
  );
}

CancellationForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  pets: PropTypes.array.isRequired,
  onAddItem: PropTypes.func.isRequired,
  cancellationsInProgress: PropTypes.array.isRequired,
};

RefundAmount.propTypes = {
  type: PropTypes.string.isRequired,
  petData: PropTypes.object.isRequired,
};

export default CancellationForm;
