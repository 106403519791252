import {
  Create,
  FileField,
  FileInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';

import { analyticsTrack } from '@pumpkincare/analytics';

import {
  MGA_CLAIM_ATTACHMENT_TYPES,
  MGA_DOCUMENT_TYPES,
} from '../../../constants/claimAttachmentDocumentTypes';
import {
  DOCUMENT_ALLOWED_TYPES,
  DOCUMENT_MAX_SIZE,
} from '../../../constants/documents';
import RoutePaths from '../../../routes';
import { uploadFile } from '../service/claimService';
import { uploadClaimAttachmentStyle } from './upload-claim-attachment-style';

function CustomToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton label='Upload File' />
    </Toolbar>
  );
}

export default function UploadClaimAttachment() {
  const notify = useNotify();
  const classes = uploadClaimAttachmentStyle();
  const location = useLocation();

  const { claim_id, user_id, pet_id, vet_id, redirect } = location.state.record;
  const redirectPage = redirect || `/${RoutePaths.claims}/${claim_id}/show`;

  const onFileRejected = () => {
    notify(
      'Error: Attachment rejected. Make sure it is the proper size and type.',
      'error'
    );
  };

  function onCreateAttachment(data) {
    const file = data.claim_attachment.rawFile;
    const documentType = data.type;

    if (
      documentType &&
      MGA_DOCUMENT_TYPES[documentType] === MGA_DOCUMENT_TYPES.claim_invoice
    ) {
      analyticsTrack({
        event: 'clicked_add_invoice_claims',
        label: 'claims add invoice',
        category: 'claims admin actions',
        claim_id: claim_id,
        ops_user_id: user_id,
        file_name: file.name,
      });
    }

    return uploadFile(user_id, file, documentType).then(s3Url => {
      return {
        type: documentType,
        claim_id: claim_id,
        pet_id: pet_id,
        user_id: user_id,
        location: s3Url,
        vet_id: vet_id || undefined,
        source_file_name: file.name,
      };
    });
  }

  return (
    <Create
      redirect={redirectPage}
      title={'Upload Claim Attachment'}
      transform={onCreateAttachment}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <SelectInput
          validate={required()}
          label='Document Type'
          source='type'
          choices={MGA_CLAIM_ATTACHMENT_TYPES}
        />

        <FileInput
          source='claim_attachment'
          label=''
          maxSize={DOCUMENT_MAX_SIZE}
          options={{
            onDropRejected: onFileRejected,
          }}
          multiple={false}
          classes={{
            root: classes.fileInput,
            dropZone: classes.fileInputDropzone,
          }}
          accept={DOCUMENT_ALLOWED_TYPES.join()}
          placeholder={
            <>
              <CloudUploadIcon style={{ color: '#2196F3' }} />
              <Typography style={{ color: '#2196F3' }}>UPLOAD FILE</Typography>
            </>
          }
        >
          <FileField target='_blank' source='src' title='title' />
        </FileInput>

        <Typography variant='h6' color='textSecondary'>
          {`File size limit: ${DOCUMENT_MAX_SIZE / 1000000} MB`}
        </Typography>
      </SimpleForm>
    </Create>
  );
}
