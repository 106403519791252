import { useEffect, useRef, useState } from 'react';
import {
  Confirm,
  Show,
  SimpleShowLayout,
  useCreate,
  useDelete,
  useNotify,
  useRedirect,
  useRefresh,
  useShowController,
  useUpdate,
} from 'react-admin';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, CircularProgress, Typography } from '@mui/material';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { analyticsTrack } from '@pumpkincare/analytics';
import {
  isClaimInAutomationStatus,
  isStatusBlockedForUpdate,
  transformDenialReasons,
  transformPreventClaimProducts,
  useClaimInvoiceLineItemById,
  useClaimPumpkinNowById,
  useDenialReasons,
  useDenialReasonsWithItems,
  useIncidentHistory,
  useInsuranceUtilization,
  usePlanByClaimId,
  usePolicyByClaimId,
  usePreventProduct,
  useServices,
  useWellnessByClaimId,
  validateInsuranceRows,
  validatePreventRows,
} from '@pumpkincare/claims';
import { useAllMedicalRecordsRequests } from '@pumpkincare/medical-records';
import {
  AddClaimAttachmentButton,
  ClaimActionMenu,
  ClaimCustomerInformation,
  ClaimDiagnosis,
  ClaimHeader,
  ClaimSubmissionInformation,
  UpdateClaimStatusModal,
} from '@pumpkincare/shared';
import {
  useCustomer,
  useFundingSource,
  useOpsList,
  useOpsUser,
  usePet,
  usePetPlanUtilization,
  useWellnessUtilization,
} from '@pumpkincare/users';

import useBooleanInput from '../../../hooks/useBooleanInput';
import {
  DENIED,
  ESTIMATE_COMPLETE,
  MGA_APPROVED,
  MGA_DUPLICATE,
  WITHDRAWN,
} from '../../../lib/claims/claim-status';
import RoutePaths from '../../../routes';
import MessageModal from '../../../shared/components/message-modal';
import { formatCurrency } from '../../../shared/utils/currencyUtils';
import { orderUsersByEmail } from '../../../shared/utils/orderUsersByEmail';
import { appendBreedName } from '../../../shared/utils/petUtils';
import {
  MedicalRecordsRequestAccordion,
  MedicalRecordsRequestProvider,
  RequestRecordsModal,
} from '../../medical-records';
import ClaimAttachmentTable from '../claim-attachment-table';
import ClaimBundleItems from '../claim-bundle-items';
import ClaimIncidentHistory from '../claim-incident-history';
import ClaimPolicyPlanInformation from '../claim-policy-plan-information';
import ClaimPumpkinNowDecision, {
  PUMPKIN_NOW_STATUSES,
} from '../claim-pumpkin-now-decision';
import ClaimReimbursementModal from '../claim-reimbursement-modal';
import ClaimSideModal from '../claim-side-modal';
import ClaimUtilization from '../claim-utilization';
import ClaimVetReimbursementModal from '../claim-vet-reimbursement-modal';
import DatagridSwitch from '../datagrid-switch';
import EditClaimAttachment from '../edit-claim-attachment';
import {
  createReimbursement,
  deleteSubclaims,
  insertClaimNote,
  runTheRules,
  updateClaimStatus,
  updateInvoiceLineItems,
} from '../service/claimService';
import StepResolver from '../step-resolver';
import {
  claimsProcessingStyle,
  claimsProcessingSx,
} from './claims-processing-style';

export default function ClaimsProcessing() {
  const [create] = useCreate();
  const [update] = useUpdate();
  const [deleteOne] = useDelete();

  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const {
    fer4114StoreLiaTableValuesInTheSession,
    fer4611CasFrontendChanges,
    punks2056BindWellnessToClaims,
    fer5427ConcurrencyErrorWhenRunningRules,
    mvx66PumpkinNow,
  } = useFlags();

  const { isLoading, record } = useShowController();

  const disableComponents = isClaimInAutomationStatus(
    record?.status,
    fer4611CasFrontendChanges
  );

  const {
    data: lineItemsByInvoiceData,
    isLoading: isLineItemByInvoiceLoading,
    isRefetching: isLineItemRefetching,
  } = useClaimInvoiceLineItemById(record?.id);

  const {
    data: medicalRecordsRequestsObject,
    isLoading: medicalRecordsRequestsLoading,
    refetch: refetchMedicalRecordsRequests,
  } = useAllMedicalRecordsRequests({ petId: record?.pet_id, claimId: record?.id });

  const { data: pet } = usePet(record?.pet_id);
  const petData = pet && appendBreedName(pet, 'breed');

  const [invoiceLineItems, setInvoiceLineItems] = useState({});
  const [pumpkinNowRequest, setPumpkinNowRequest] = useState({});

  const {
    data: incidentHistoryData = {},
    isLoading: isIncidentLoading,
    refetch: refetchIncidentHistory,
  } = useIncidentHistory(record?.pet_id);

  let preventSubClaim = {};

  if (punks2056BindWellnessToClaims) {
    preventSubClaim = record?.subclaims?.find(
      sub => sub.type === 'wellness' || sub.type === 'prevent'
    );
  } else {
    preventSubClaim = record?.subclaims?.find(sub =>
      record?.is_wellness ? sub.type === 'wellness' : sub.type === 'prevent'
    );
  }

  const { data: preventProductsData = [] } = usePreventProduct(
    record?.id,
    preventSubClaim
  );

  const { data: customerData, isLoading: isCustomerLoading } = useCustomer(
    record?.customer_id
  );

  const { data: fundingSourceData } = useFundingSource(record?.customer_id);
  const { data: currentOpsUserData, isLoading: isOpsUserLoading } = useOpsUser();
  const { data: opsData } = useOpsList();
  const { data: serviceData = [] } = useServices();

  const { isLoading: isPumpkinNowLoading } = useClaimPumpkinNowById(
    record?.id,
    mvx66PumpkinNow && (record?.is_pumpkin_now_eligible || false),
    {
      onSuccess: data => {
        setPumpkinNowRequest(data);
      },
    }
  );

  const serviceDataFormatted = serviceData.map(service => {
    return {
      id: service.id,
      name: service.subservice_name,
    };
  });

  const incidentHistoryOptions = incidentHistoryData.list?.length
    ? incidentHistoryData.list.map(incident => {
        return {
          value: incident.id,
          label: (
            <>
              <span style={{ opacity: '60%', fontSize: '14px' }}>
                {incident.latest_occurrence_date} &nbsp;
              </span>
              <span style={{ fontSize: '14px', textTransform: 'capitalize' }}>
                {incident.sub_diagnosis.name.toLowerCase()} - (
                {incident.diagnosis.name.toLowerCase()})
              </span>
            </>
          ),
          toolTip: `${incident.diagnosis.name} - ${incident.sub_diagnosis.name}`,
        };
      })
    : [];

  const preventProductDataFormatted =
    transformPreventClaimProducts(preventProductsData);

  const { data: denialReasonsData } = useDenialReasons();
  const { data: insuranceDenialReasonData } = useDenialReasonsWithItems();
  const { data: planData, isLoading: isPlanLoading } = usePlanByClaimId(record?.id);
  const { data: policyData, isLoading: isPolicyLoading } = usePolicyByClaimId(
    record?.id
  );
  const { data: wellnessData } = useWellnessByClaimId(record?.id, {
    enabled: !!record?.id,
  });

  const { data: petPlanUtilizationData, isLoading: isPEPUtilizationLoading } =
    usePetPlanUtilization(planData?.addedFakeId ? null : planData?.id);
  const {
    data: petInsuranceUtilizationData,
    isLoading: isInsuranceUtilizationLoading,
  } = useInsuranceUtilization(policyData?.addedFakeId ? null : policyData?.id);
  const { data: wellnessUtilizationData, isLoading: isWellnessUtilizationLoading } =
    useWellnessUtilization(wellnessData?.addedFakeId ? null : wellnessData?.id);

  const isUtilizationFetching =
    !petData ||
    (planData?.id && isPEPUtilizationLoading) ||
    (policyData?.id && isInsuranceUtilizationLoading) ||
    (wellnessData?.id && isWellnessUtilizationLoading);

  const insuranceDenialReason = transformDenialReasons(insuranceDenialReasonData);

  const [rows, setRows] = useState([]);
  const [lineItems, setLineItems] = useState([
    {
      id: 1,
      name: '',
      quantity: 1,
      claimed_amount: 0,
      loss_date: null,
      coverage_type: null,
    },
  ]);
  const [pepRows, setPepRows] = useState([]);
  const [bundleItems, setBundleItems] = useState([]);

  const [claimAttachment, setClaimAttachment] = useState();
  const claimlineItemiId = useRef(2);
  const [isUpdateStatusModalOpen, toggleUpdateStatusModal] = useBooleanInput();
  const [isEditClaimAttachmentModalOpen, toggleEditClaimAttachmentModal] =
    useBooleanInput();

  const [isConfirmationModalOpen, toggleConfirmationModal] = useBooleanInput();
  const [isRequestRecordsModalOpen, toggleRequestRecordsModal] = useBooleanInput();
  const [showBundleList, setShowBundleList] = useBooleanInput(true);
  const [isSaving, toggleSaving] = useBooleanInput();
  const [isRowEditing, toggleRowEditing] = useBooleanInput(false);
  const [isClaimSideModalOpen, toggleClaimSideModal] = useBooleanInput();
  const [isReimbursementModalOpen, toggleReimbursementModal] = useBooleanInput();
  const [isAssignOpsEditMode, toggleAssignOpsEditMode] = useBooleanInput(false);
  const [isClaimMessageModalOpen, toggleClaimMessageModalOpen] =
    useBooleanInput(true);
  const [isOutsidePolicyPeriod, toggleOutsidePolicyPeriod] = useBooleanInput(false);
  const [hasNegativeLineItemAmount, toggleNegativeLineItemAmount] =
    useBooleanInput(false);
  const [isPumpkinNowSubmitting, togglePumpkinNowSubmitting] =
    useBooleanInput(false);
  const shouldVerifyPumpkinNow =
    mvx66PumpkinNow &&
    pumpkinNowRequest?.pumpkin_now_status === PUMPKIN_NOW_STATUSES.PENDING;

  const classes = claimsProcessingStyle();

  useEffect(() => {
    if (!isLoading && !isOpsUserLoading) {
      analyticsTrack({
        event: 'opened_claim_claims',
        label: 'claims opened claim',
        category: 'claim admin actions',
        claim_id: record?.id,
        ops_user_id: currentOpsUserData?.id,
      });
    }
  }, [isLoading, isOpsUserLoading]);

  function handleToggleReimbursementModal() {
    const insuranceError = rows.length && validateInsuranceRows(rows);
    const preventError = pepRows.length && validatePreventRows(pepRows);

    if (insuranceError) {
      notify(
        `Please check the insurance line items and fill in all fields then run the rules to save before trying to reimburse.`,
        {
          type: 'warning',
        }
      );
      return;
    }

    if (preventError) {
      notify(
        `Please check the prevent line items and fill in all fields then run the rules to save before trying to reimburse.`,
        {
          type: 'warning',
        }
      );
      return;
    }

    toggleReimbursementModal();
  }

  function storeDecisionsPromise(payload) {
    return update(RoutePaths.storeDecisions, payload, {
      returnPromise: true,
    });
  }

  function removePendingLines(payload) {
    return update(RoutePaths.removePendingLines, payload, {
      returnPromise: true,
    });
  }

  function calculateUpdateClaim(payload) {
    return update(RoutePaths.calculateUpdateClaim, payload, {
      returnPromise: true,
    });
  }

  function handleEditClaimAttachmentClick(claimAttachment) {
    if (claimAttachment.claim_id) {
      setClaimAttachment(claimAttachment);
    } else {
      setClaimAttachment({ ...claimAttachment, claim_id: record.id });
    }
    toggleEditClaimAttachmentModal();
  }

  function renderMoneyInput(params) {
    const formattedAmount = formatCurrency(params.value, { areCents: false });
    return <div>{`${formattedAmount}`}</div>;
  }

  function commitCellChanges(oldRow, newRow) {
    if (newRow.decision !== 'denied') {
      newRow.denial_reason = [];
    }

    setRows(rows.map(row => (row.id === newRow.id ? newRow : row)));
    toggleRowEditing();
    return newRow;
  }

  function commitPepCellChanges(oldRow, newRow) {
    if (newRow.decision !== 'denied') {
      newRow.denial_reason = null;
    }

    const mergedPreventLineItems = pepRows.map(pepRow =>
      pepRow.id === newRow.id ? newRow : pepRow
    );

    const mergedLineItems = mergedPreventLineItems.map(merged => {
      if (oldRow.decision !== newRow.decision) {
        return {
          ...merged,
          approved_amount:
            merged.decision === 'approved' ? merged.claimed_amount : 0,
          declined_amount: merged.decision === 'denied' ? merged.claimed_amount : 0,
          approved_qty: merged.decision === 'approved' ? merged.quantity : 0,
        };
      }

      return merged;
    });

    const bundleItemList = mergedLineItems.filter(
      lineItem => lineItem.item_type?.name === 'Bundle'
    );

    setShowBundleList(bundleItemList.length > 0);

    setBundleItems(
      bundleItemList.map(item => {
        if (item.bundle_items.length === 0) {
          item.bundle_items = [
            {
              id: 1,
              item_type: '',
              quantity: '',
              approved_amount: '',
              included_in_prevent: false,
            },
          ];
          return item;
        }
        return {
          ...item,
          bundle_items: item.bundle_items.map(bundle => ({
            ...bundle,
            included_in_prevent: !!bundle.is_included,
            item_type: bundle.prevent_product_id,
            approved_amount: bundle.approved_amount / 100,
          })),
        };
      })
    );

    setPepRows(mergedLineItems);
    toggleRowEditing();

    return mergedLineItems.find(row => row.id === newRow.id);
  }

  function handleConfirmClassificationSubmission() {
    const areCents = !invoiceLineItems?.invoices;
    updateInvoiceLineItems(
      toggleSaving,
      petData,
      invoiceLineItems?.invoices
        ? invoiceLineItems
        : lineItemsByInvoiceData?.response,
      create,
      record,
      toggleConfirmationModal,
      refresh,
      notify,
      lineItems,
      update,
      areCents
    );

    if (fer4114StoreLiaTableValuesInTheSession) {
      sessionStorage.removeItem('cachedInvoiceData');
    }

    analyticsTrack({
      event: 'clicked_next_step1_claims',
      label: 'claims clicked next step 1',
      category: 'claims admin actions',
      claim_id: record?.id,
      ops_user_id: currentOpsUserData?.id,
    });
  }

  function handleNextButtonClick(record) {
    if (record.step === 1) {
      let error = '';
      const lineItems = invoiceLineItems?.invoices
        ? invoiceLineItems
        : lineItemsByInvoiceData;
      lineItems.invoices?.forEach(invoice => {
        invoice.line_items.forEach(lineItem => {
          const wellnessOnly = petData?.wellness && !petData?.latest_pet_policy;
          if (
            !lineItem?.description ||
            !lineItem?.quantity ||
            lineItem?.description.replaceAll(' ', '') === '' ||
            lineItem?.quantity === 0 ||
            !lineItem?.loss_date ||
            (!wellnessOnly && !lineItem?.line_item_type)
          ) {
            error = `Please fill in all line item information for invoice ${invoice.source_file_name}`;
          }
        });
      });
      if (error) {
        notify(error, {
          type: 'warning',
        });
        return;
      }
      toggleConfirmationModal();
    }

    if (record.step === 2) {
      runTheRules({
        record,
        rows,
        pepRows,
        toggleSaving,
        refresh,
        notify,
        insuranceDenialReason,
        bundleItems,
        setBundleItems,
        removePendingLines,
        storeDecisionsPromise,
        calculateUpdateClaim,
        fer5427ConcurrencyErrorWhenRunningRules,
      });
    }
  }

  function handleBackButtonClick(record) {
    if (record.step <= 3) {
      setInvoiceLineItems({});

      analyticsTrack({
        event: 'clicked_previous_step2_claims',
        label: 'claims previous step 2',
        category: 'claims admin actions',
        claim_id: record?.id,
        ops_user_id: currentOpsUserData?.id,
      });

      deleteSubclaims(record, refresh, notify, deleteOne);
    }
  }

  function handleMenuIconClick() {
    toggleClaimSideModal();
  }

  function handleSendNoteClick(claimRecord, notes, refetchClaimNotes) {
    insertClaimNote(
      toggleSaving,
      create,
      claimRecord,
      notes,
      refetchClaimNotes,
      notify,
      currentOpsUserData
    );
  }

  function handleUpdateClaimStatus(record, status) {
    updateClaimStatus(
      record,
      status,
      notify,
      toggleSaving,
      update,
      toggleUpdateStatusModal,
      refresh
    );
  }

  function handleReimbursement(record, payload = {}) {
    createReimbursement(
      record,
      payload,
      toggleSaving,
      create,
      refresh,
      toggleReimbursementModal,
      notify
    );
  }

  function handleLineItemChange(value, id, key) {
    setLineItems(
      lineItems.map((lineItemValue, index) => {
        if (index !== id) {
          return lineItemValue;
        }
        return { ...lineItemValue, [key]: value };
      })
    );
  }

  function handleAddLineItem() {
    setLineItems(
      lineItems.concat({
        id: claimlineItemiId.current++,
        name: '',
        quantity: 1,
        claimed_amount: 0,
        loss_date: null,
        coverage_type: null,
      })
    );

    analyticsTrack({
      event: 'clicked_add_line_item_claims',
      label: 'claims add line items',
      category: 'claims admin actions',
      claim_id: record?.id,
      ops_user_id: currentOpsUserData?.id,
    });
  }

  function handleRemoveLineItem(key) {
    if (lineItems.length > 1) {
      setLineItems(lineItems.filter((lineItemValue, index) => index !== key));
    }

    analyticsTrack({
      event: 'clicked_delete_line_item_claims',
      label: 'claims delete line items',
      category: 'claims admin actions',
      claim_id: record?.id,
      ops_user_id: currentOpsUserData?.id,
    });
  }

  function handleBundleItemChange(
    value,
    itemIndex,
    key,
    bundleItemId,
    preventBundleItems
  ) {
    const bundleItemChanged = preventBundleItems.map(bundleItemValue => {
      if (bundleItemValue.id === bundleItemId) {
        bundleItemValue.bundle_items[itemIndex][key] = value;
      }
      return bundleItemValue;
    });
    if (key === 'included_in_prevent' || key === 'quantity') {
      onIncludeInPreventPlan(bundleItemChanged);
    } else {
      setBundleItems(bundleItemChanged);
    }
  }

  function onIncludeInPreventPlan(bundleItem) {
    const totalItemQuantity = bundleItem[0].bundle_items.reduce((sum, item) => {
      const quantity = parseInt(item.quantity) > 0 ? parseInt(item.quantity) : 1;
      return sum + quantity;
    }, 0);

    bundleItem[0].bundle_items = bundleItem[0].bundle_items.map((item, index) => {
      const itemQuantity = item.quantity > 0 ? item.quantity : 1;

      if (!item.quantity) {
        item.quantity = 1;
      }

      item['approved_amount'] = Math.round(
        (bundleItem[0].approved_amount / totalItemQuantity) * itemQuantity
      );
      item['ordinal'] = index + 1;
      return item;
    });
    setBundleItems(bundleItem);
  }

  function handleBundleItemAdd(preventBundleLineItems, bundleItemId) {
    setBundleItems(
      preventBundleLineItems.map(item => {
        if (item.id === bundleItemId) {
          item.bundle_items = item.bundle_items.concat({
            id: claimlineItemiId.current++,
            item_type: '',
            quantity: 0,
            approved_amount: '',
            included_in_prevent: false,
          });
        }
        return item;
      })
    );
  }

  function handleBundleItemDelete(bundleItemId, preventBundleLineItems, key) {
    setBundleItems(
      preventBundleLineItems.map(bundle => {
        if (bundle.id === bundleItemId && bundle.bundle_items.length > 1) {
          bundle.bundle_items = bundle.bundle_items.filter(
            (item, index) => index !== key
          );
        }
        return bundle;
      })
    );
  }

  function handleOpsAssignment(values, claimId) {
    if (values) {
      toggleSaving();

      update(
        'claims/assignment',
        {
          meta: { method: 'PATCH' },
          data: { claims_ids: [claimId], ops_user_id: values.id },
        },
        {
          onSuccess: () => {
            toggleSaving();
            toggleAssignOpsEditMode();
            refresh();
          },
          onError: error => {
            toggleSaving();
            notify(`There was an error while assigning a claim: ${error.message}`, {
              type: 'error',
            });
          },
        }
      );
    }
  }

  function handleEditModeToggle() {
    if (!isSaving && !isStatusBlockedForUpdate(record.status)) {
      toggleAssignOpsEditMode();
    }
  }

  function submitPumpkinNowDecision(action, deferralReason, notes) {
    const payload = { pumpkin_now_status: action };
    if (deferralReason) payload.deferral_reason = deferralReason;
    if (notes) payload.deferral_notes = notes;

    togglePumpkinNowSubmitting();
    update(
      `claims/${record?.id}/pumpkin-now/${pumpkinNowRequest?.id}`,
      {
        meta: { method: 'PATCH' },
        data: payload,
      },
      {
        onSuccess: data => {
          togglePumpkinNowSubmitting();
          setPumpkinNowRequest(data);
        },
        onError: error => {
          togglePumpkinNowSubmitting();
          notify(
            `There was an error while submitting pumpkin now decision. ${error.message}`,
            {
              type: 'error',
            }
          );
        },
      }
    );
  }

  const wellnessCoverageStartDate = planData
    ? planData.coverage_start_date
    : wellnessData?.coverage_start_date;

  return isLoading === true ? null : (
    <Show
      hasEdit={false}
      classes={{
        root: classes.root,
        noActions: classes.noActions,
      }}
    >
      {record.step === 1 && isOutsidePolicyPeriod ? (
        <Alert
          icon={<InfoOutlinedIcon fontSize='inherit' />}
          variant='filled'
          className={classes.alert}
          style={{
            marginBottom:
              (policyData?.has_cancel_rewrite || hasNegativeLineItemAmount) &&
              '10px',
          }}
        >
          Loss date(s) are outside of associated coverage period. Please review the
          loss dates in red to make sure they are correct.
        </Alert>
      ) : null}
      {record.step === 1 && hasNegativeLineItemAmount ? (
        <Alert
          icon={<InfoOutlinedIcon fontSize='inherit' />}
          variant='filled'
          className={classes.alert}
          style={{ marginBottom: policyData?.has_cancel_rewrite && '10px' }}
        >
          Line item amount(s) are negative. Please review the line items in red to
          make changes.
        </Alert>
      ) : null}
      {policyData?.has_cancel_rewrite ? (
        <Alert
          icon={<InfoOutlinedIcon fontSize='inherit' />}
          variant='filled'
          className={classes.alert}
        >
          This pet has a cancel rewrite
        </Alert>
      ) : null}
      <SimpleShowLayout className={classes.root}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ClaimHeader
            opsData={opsData ? orderUsersByEmail(opsData) : []}
            isAssignOpsEditMode={isAssignOpsEditMode}
            onEditModeToggle={handleEditModeToggle}
            onOpsChange={handleOpsAssignment}
            disableComponents={disableComponents}
            isPumpkinNowLoading={isPumpkinNowLoading}
            pumpkinNowStatus={pumpkinNowRequest?.pumpkin_now_status}
          />
          <ClaimActionMenu
            toggleRequestRecordsModal={toggleRequestRecordsModal}
            toggleUpdateStatusModal={toggleUpdateStatusModal}
            onMenuIconClick={handleMenuIconClick}
            disableButton={isSaving || disableComponents}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '24px', marginBottom: '16px' }}>
          {isCustomerLoading ? (
            <CircularProgress />
          ) : (
            <ClaimCustomerInformation customerData={customerData} />
          )}

          {isPlanLoading === false && isPolicyLoading === false ? (
            <>
              <ClaimPolicyPlanInformation
                petData={petData}
                policyData={policyData}
                planData={planData}
                wellnessData={wellnessData}
              />
              <MessageModal
                onClose={() => {
                  toggleClaimMessageModalOpen();
                }}
                isOpen={
                  isClaimMessageModalOpen &&
                  (policyData?.has_pending_policy_changes ||
                    policyData?.latest_policy_changed_at > record?.created_at) &&
                  ![
                    ESTIMATE_COMPLETE,
                    MGA_APPROVED,
                    MGA_DUPLICATE,
                    WITHDRAWN,
                    DENIED,
                  ].includes(record.status)
                }
                title='Utilization Update'
                buttonTitle='VIEW UPDATE'
                message='This pet parent made updates to their policy that impacts their utilization. Changes may need to be applied to this claim.'
                onClick={() => {
                  redirect(`/${RoutePaths.pets}/${record.pet_id}/show`);
                }}
              />
            </>
          ) : (
            <CircularProgress />
          )}
        </div>
        <div className={classes.claimWrapper}>
          <div
            className={classNames(classes.claimContent, {
              [classes.hideClaimContent]: record.step !== 2,
            })}
          >
            <div>
              {record.step === 2 ? (
                <Typography className={classes.title}>Claimed Line Items</Typography>
              ) : null}
            </div>
            <div style={{ display: 'flex' }}>
              <StepResolver
                handleNextButtonClick={handleNextButtonClick}
                handleBackButtonClick={handleBackButtonClick}
                toggleReimbursementModal={handleToggleReimbursementModal}
                disableButton={
                  isSaving ||
                  isRowEditing ||
                  disableComponents ||
                  hasNegativeLineItemAmount ||
                  shouldVerifyPumpkinNow
                }
                classes={classes}
              />
            </div>
          </div>
          {shouldVerifyPumpkinNow ? (
            <ClaimPumpkinNowDecision
              onSubmit={submitPumpkinNowDecision}
              isSubmitting={isPumpkinNowSubmitting}
            />
          ) : null}
          {isSaving ? (
            <CircularProgress />
          ) : (
            <DatagridSwitch
              lineItems={lineItems}
              isLineItemRefetching={isLineItemRefetching}
              lineItemsByInvoiceData={lineItemsByInvoiceData}
              onEditInvoiceClick={handleEditClaimAttachmentClick}
              onLineItemAdd={handleAddLineItem}
              policyData={policyData}
              wellnessData={wellnessData}
              petData={petData}
              isLineItemByInvoiceLoading={isLineItemByInvoiceLoading}
              onLineItemDelete={handleRemoveLineItem}
              onLineItemChange={handleLineItemChange}
              setRows={setRows}
              toggleRowEditing={toggleRowEditing}
              handleNextButtonclick={handleNextButtonClick}
              setPepRows={setPepRows}
              rows={rows}
              pepRows={pepRows}
              onCommitCellChanges={commitCellChanges}
              onCommitLineItemChanges={setInvoiceLineItems}
              onCommitPepCellChanges={commitPepCellChanges}
              renderMoneyInput={renderMoneyInput}
              denialReasonData={denialReasonsData}
              insuranceDenialReason={insuranceDenialReason}
              preventProductsData={preventProductDataFormatted}
              serviceData={serviceDataFormatted}
              incidentHistoryOptions={incidentHistoryOptions}
              currentOpsUserData={currentOpsUserData}
              toggleOutsidePolicyPeriod={toggleOutsidePolicyPeriod}
              toggleNegativeLineItemAmount={toggleNegativeLineItemAmount}
            />
          )}
          {record?.step <= 4 && showBundleList ? (
            <ClaimBundleItems
              bundleItems={bundleItems}
              preventSubClaim={preventSubClaim}
              onChange={handleBundleItemChange}
              onBundleItemAdd={handleBundleItemAdd}
              onBundleItemDelete={handleBundleItemDelete}
              preventProductsData={preventProductDataFormatted}
            />
          ) : null}

          {isUtilizationFetching ? <CircularProgress /> : null}

          {!isUtilizationFetching ? (
            <ClaimUtilization
              pepUtilization={petPlanUtilizationData}
              insUtilization={petInsuranceUtilizationData}
              wellnessUtilization={wellnessUtilizationData}
            />
          ) : null}

          {isIncidentLoading ? (
            <CircularProgress />
          ) : (
            <ClaimIncidentHistory
              classes={classes}
              sx={claimsProcessingSx}
              isSaving={isSaving}
              incidentHistoryData={incidentHistoryData?.list || []}
              disableDeletion={disableComponents}
              wellnessCoverageStartDate={wellnessCoverageStartDate}
              coverageStartDate={policyData?.coverage_start_date}
              accidentCoverageStartDate={policyData?.accident_coverage_start_date}
              petId={record?.pet_id}
              toggleSaving={toggleSaving}
              refetchIncidentHistory={refetchIncidentHistory}
              currentOpsUserId={currentOpsUserData?.id}
              claimSubmissionId={record?.id}
            />
          )}
          <ClaimSubmissionInformation />
          <ClaimDiagnosis />
          <Typography className={classes.attachmentsTitle}>
            CLAIM ATTACHMENTS
          </Typography>
          <MedicalRecordsRequestProvider>
            <div style={{ width: '650px', marginBottom: '38px' }}>
              <ClaimAttachmentTable
                showEditButton
                showDeleteButton
                onEditClick={handleEditClaimAttachmentClick}
                currentOpsUserData={currentOpsUserData}
                claimId={record?.id}
                disableButton={disableComponents}
                claimRecord={record}
              />
            </div>

            <AddClaimAttachmentButton
              userId={record?.customer_id}
              petId={record?.pet_id}
              vetId={record?.vet_id}
              claimId={record?.id}
              routes={RoutePaths}
              disableButton={isSaving || disableComponents}
            />

            <MedicalRecordsRequestAccordion
              medicalRecordsRequestsObject={medicalRecordsRequestsObject || []}
              isLoading={medicalRecordsRequestsLoading}
              refetch={refetchMedicalRecordsRequests}
              disableRequestCreation={disableComponents}
              petId={record?.pet_id}
              userId={record?.customer_id}
              claimId={record?.id}
            />
          </MedicalRecordsRequestProvider>
        </div>

        <UpdateClaimStatusModal
          open={isUpdateStatusModalOpen}
          onClose={toggleUpdateStatusModal}
          onStatusUpdate={handleUpdateClaimStatus}
          disableButton={isSaving}
        />
        <EditClaimAttachment
          open={isEditClaimAttachmentModalOpen}
          onClose={toggleEditClaimAttachmentModal}
          claimAttachment={claimAttachment}
          currentOpsUserData={currentOpsUserData}
        />
        <ClaimSideModal
          open={isClaimSideModalOpen}
          onClose={toggleClaimSideModal}
          opsUserList={opsData}
          onSendNoteClick={handleSendNoteClick}
        />
        {isRequestRecordsModalOpen ? (
          <RequestRecordsModal
            onClose={toggleRequestRecordsModal}
            onSuccess={() => {
              toggleRequestRecordsModal();
              refetchMedicalRecordsRequests();
            }}
            petId={record?.pet_id}
            userId={record?.customer_id}
            claimId={record?.id}
          />
        ) : null}

        {record?.send_reimbursement_to_vet ? (
          <ClaimVetReimbursementModal
            open={isReimbursementModalOpen}
            onClose={toggleReimbursementModal}
            onReimburseClick={handleReimbursement}
            disabledButton={isSaving}
          />
        ) : (
          <ClaimReimbursementModal
            open={isReimbursementModalOpen}
            onClose={toggleReimbursementModal}
            customerData={customerData}
            fundingSourceData={fundingSourceData}
            onReimburseClick={handleReimbursement}
            disabledButton={isSaving}
          />
        )}
        <Confirm
          isOpen={isConfirmationModalOpen}
          title='Ready to get processing?'
          content='Please make sure these line items are under the correct coverage type.'
          loading={isSaving}
          onConfirm={handleConfirmClassificationSubmission}
          onClose={toggleConfirmationModal}
        />
      </SimpleShowLayout>
    </Show>
  );
}
