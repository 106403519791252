import { Fragment, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import IconAdd from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  formatAuditObject,
  formatRequestedRecordsText,
  MEDICAL_RECORD_REQUEST_COMPLETE,
  MEDICAL_RECORD_REQUEST_INACTIVE,
  MEDICAL_RECORD_REQUEST_OPEN,
} from '@pumpkincare/medical-records';

import { parseAndFormatDate } from '../../../../shared/utils';
import MarkReceivedModal from '../mark-received-modal';
import { useMedicalRecordsRequestModal } from '../medical-records-request-provider';
import RequestRecordsModal from '../request-records-modal';
import ViewMedicalRecordsRequest from '../view-medical-records-request';
import { medicalRecordsRequestAccordionStyles } from './medical-records-request-accordion-style';

const ACTION = {
  [MEDICAL_RECORD_REQUEST_OPEN]: 'Mark Received',
  [MEDICAL_RECORD_REQUEST_INACTIVE]: 'No Response',
  [MEDICAL_RECORD_REQUEST_COMPLETE]: (
    <>
      <CheckIcon /> Received
    </>
  ),
};

function MedicalRecordsRequestAccordion({
  medicalRecordsRequestsObject,
  isLoading,
  refetch,
  disableRequestCreation,
  petId,
  userId,
  claimId,
  accordionTitle = 'Medical Record Requests',
  isPetView = false,
}) {
  const classes = medicalRecordsRequestAccordionStyles();
  const {
    isNewRequest,
    viewDetailsId,
    markReceivedId,
    closeRecordsModal,
    openNewRequest,
    openViewDetails,
    openMarkReceived,
  } = useMedicalRecordsRequestModal();
  const medicalRecordsRequestsData = isLoading ? [] : medicalRecordsRequestsObject;

  /*
   * route from gladly will have ?openMrcModal=<requestId>
   */
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const queryMrcId = queryParams.get('openMrcModal');

  /*
   * protect against non-matching / invalid id from gladly
   */
  const matchingId = medicalRecordsRequestsData.find(
    request => request.id === queryMrcId
  )?.id;

  useEffect(() => {
    if (matchingId) openViewDetails(matchingId);
  }, [matchingId, openViewDetails]);

  return (
    <>
      <Accordion square defaultExpanded classes={{ root: classes.root }}>
        <AccordionSummary
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
          }}
          aria-controls='all-medical-record-requests'
          id='all-medical-record-requests'
          disableRipple
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        >
          <Typography variant='h6'>{accordionTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          {!medicalRecordsRequestsData.length && isLoading ? (
            <CircularProgress className={classes.loader} />
          ) : (
            <>
              {!medicalRecordsRequestsData.length ? (
                <Typography variant='body2'>
                  There are no medical record requests associated with this
                  {isPetView ? ' policy' : ' claim'}.
                </Typography>
              ) : (
                <div className={classes.grid}>
                  <div className={classes.miniText}>REQ ID</div>
                  <div className={classes.miniText}>DATE SENT</div>
                  <div className={classes.miniText}>VET CLINIC</div>
                  <div className={classes.miniText}>REQUESTED RECORDS</div>
                  <div className={classes.miniText}>LATEST STATUS</div>
                  <div />

                  {medicalRecordsRequestsData.map((row, idx) => {
                    /*
                     RA quirk - loading ALL medical records returns only the latest audit, but
                     opening the view modal will load all the audits and override the previous state
                     */
                    const audit = formatAuditObject(row.audit[row.audit.length - 1]);
                    const isOpen = row.status === MEDICAL_RECORD_REQUEST_OPEN;

                    return (
                      <Fragment key={row.id + idx}>
                        <div className={classes.cell}>
                          <Typography variant='body2'>{row.id}</Typography>
                          {isPetView ? (
                            <Link
                              to={`/claims/${row.claim_submission_id}/show`}
                              className={classes.link}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {row.claim_submission_id}
                            </Link>
                          ) : null}
                        </div>
                        <div className={classes.cell}>
                          <Typography variant='body2'>
                            {parseAndFormatDate(row.created_at, 'M/D/YYYY')}
                          </Typography>
                        </div>
                        <div className={classes.cell}>
                          <Typography
                            variant='body2'
                            className={classes.truncateOverflow}
                          >
                            {row.clinic_name}
                            {row.vet_practice ? (
                              <span className={classes.vetInfo}>
                                {row.vet_practice.normalized_addr}
                              </span>
                            ) : null}
                            <span className={classes.vetInfo}>
                              {row.contact_email}
                            </span>
                          </Typography>
                        </div>
                        <div className={classes.cell}>
                          <Typography
                            variant='body2'
                            className={classes.truncateOverflow}
                          >
                            {formatRequestedRecordsText(row.records).map(record => (
                              <span key={record.description || record.type}>
                                {record.text}
                              </span>
                            ))}
                          </Typography>
                        </div>
                        <div className={classes.cell}>
                          <Typography variant='body2'>
                            <span className={classes.truncateOverflow}>
                              {audit.event}
                            </span>
                            <span className={classes.miniText}>{audit.detail}</span>
                          </Typography>
                        </div>
                        <div className={classes.cell}>
                          <Button
                            className={classes.tableAction}
                            onClick={() => openViewDetails(row.id)}
                          >
                            <Typography variant='body2'>View Details</Typography>
                          </Button>
                          <Button
                            className={classes.tableAction}
                            onClick={() => openMarkReceived(row.id)}
                            disabled={!isOpen}
                          >
                            <Typography variant='body2'>
                              {ACTION[row.status]}
                            </Typography>
                          </Button>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              )}

              {isLoading ? (
                <CircularProgress className={classes.loader} size={20} />
              ) : null}

              <Button
                className={classes.newRequest}
                onClick={openNewRequest}
                variant='outlined'
                disabled={disableRequestCreation}
                startIcon={<IconAdd />}
              >
                New Request
              </Button>
            </>
          )}
        </AccordionDetails>
      </Accordion>

      {isNewRequest ? (
        <RequestRecordsModal
          onClose={closeRecordsModal}
          onSuccess={() => {
            closeRecordsModal();
            refetch();
          }}
          petId={petId}
          userId={userId}
          claimId={claimId}
        />
      ) : null}

      {viewDetailsId ? (
        <ViewMedicalRecordsRequest
          requestId={viewDetailsId}
          onClose={closeRecordsModal}
          onSuccess={() => refetch()}
        />
      ) : null}

      {markReceivedId ? (
        <MarkReceivedModal
          requestId={markReceivedId}
          onClose={closeRecordsModal}
          onSuccess={() => refetch()}
        />
      ) : null}
    </>
  );
}

MedicalRecordsRequestAccordion.propTypes = {
  medicalRecordsRequestsObject: PropTypes.arrayOf(
    PropTypes.shape({
      additional_notes: PropTypes.string,
      additional_owner: PropTypes.string,
      audit: PropTypes.arrayOf(
        PropTypes.shape({
          action: PropTypes.string.isRequired,
          created_at: PropTypes.string.isRequired,
          initiator: PropTypes.string.isRequired,
          notes: PropTypes.string,
          reason: PropTypes.string,
        })
      ).isRequired,
      claim_submission_id: PropTypes.string,
      clinic_name: PropTypes.string.isRequired,
      contact_email: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      records: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          start_date: PropTypes.string,
          type: PropTypes.string.isRequired,
        })
      ).isRequired,
      status: PropTypes.string.isRequired,
      vet_practice: PropTypes.shape({
        claims_email: PropTypes.string.isRequired,
        normalized_addr: PropTypes.string.isRequired,
        vet_name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
  disableRequestCreation: PropTypes.bool,
  petId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  claimId: PropTypes.string,
  accordionTitle: PropTypes.string,
  isPetView: PropTypes.bool,
};

export default MedicalRecordsRequestAccordion;
