import { useGetOne } from 'react-admin';

export function useClaimById(claimId) {
  return useGetOne('claims', { id: claimId }, { enabled: !!claimId });
}

export function useClaimInvoiceLineItemById(claimId) {
  return useGetOne(
    'claims',
    { id: `${claimId}/invoice-line-items` },
    {
      enabled: !!claimId,
      select: response => {
        const invoices = response.invoices.map(invoice => {
          const lineItemsFormatted = invoice.line_items.map(lineItem => {
            return {
              ...lineItem,
              total_amount: lineItem.total_amount / 100,
              cost_per_unit_amount: lineItem.cost_per_unit_amount / 100,
            };
          });
          return { ...invoice, line_items: lineItemsFormatted };
        });
        return { response, invoices: invoices };
      },
    }
  );
}

export function useClaimPumpkinNowById(
  claimId,
  isPumpkinNowEligible,
  options = { enabled: true }
) {
  return useGetOne(
    'claims',
    { id: `${claimId}/pumpkin-now` },
    { ...options, enabled: !!claimId && isPumpkinNowEligible }
  );
}
