import { CreateButton } from 'react-admin';
import PropTypes from 'prop-types';

function AddClaimAttachmentButton({
  routes,
  disableButton,
  claimId,
  userId,
  petId,
  vetId,
  redirect,
  label = 'ADD CLAIM ATTACHMENTS',
  ...props
}) {
  const claimRecord = {
    claim_id: claimId,
    user_id: userId,
    pet_id: petId,
    vet_id: vetId,
    redirect,
  };

  return (
    <CreateButton
      disabled={disableButton}
      label={label}
      resource={`/${routes.documentsV2}/create`}
      state={{ record: claimRecord }}
      sx={{
        fontSize: 14,
        padding: '6px 8px',
        border: '1px solid #CDCFD3',
        '& svg': { color: '#5368F5;', fontSize: '20px !important' },
        '&:disabled': {
          '& svg': { color: '#00000042', fontSize: '20px !important' },
        },
      }}
      {...props}
    />
  );
}

AddClaimAttachmentButton.propTypes = {
  routes: PropTypes.object,
  disableButton: PropTypes.bool,
  label: PropTypes.string,
  claimId: PropTypes.string,
  userId: PropTypes.string,
  petId: PropTypes.string,
  vetId: PropTypes.string,
  redirect: PropTypes.string,
};

export default AddClaimAttachmentButton;
