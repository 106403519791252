import makeStyles from '@mui/styles/makeStyles';

const medicalRequestRecordsInfoBannerStyle = makeStyles(
  {
    card: {
      marginBottom: '10px',
      boxShadow: 'none',
      padding: '6px 16px',
      borderRadius: '4px',
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px !important',
    },
    infoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'left',
      marginLeft: '10px',
    },
    logUpdateButton: {
      backgroundColor: 'transparent',
      padding: '0px',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
    bannerTitle: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    bannerDescription: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
  },
  { name: 'medical-records-request-info-banner' }
);

export { medicalRequestRecordsInfoBannerStyle };
