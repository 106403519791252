import makeStyles from '@mui/styles/makeStyles';

const claimPumpkinNowDecisionStyle = makeStyles(
  {
    contentWrapper: {
      padding: '20px',
      boxShadow: '0px 7px 18px 2px rgba(0, 0, 0, 0.3)',
      margin: '50px 0px',
      borderRadius: '4px',
    },
    pumpkinNowTitle: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '150%',
      letterSpacing: '0.15px',
      color: '#000000',
      marginBottom: '15px',
    },
    buttonGroup: {
      marginLeft: '10px',
      borderColor: '#5368F5',
      '& .MuiToggleButton-root': {
        borderColor: 'rgba(83, 104, 245, 0.5)',
        borderRadius: '1',
        margin: '20px 0 18px',
        '&.Mui-selected': {
          backgroundColor: '#5368F5',
          color: '#FFFFFF',
        },
      },
    },
    toggleButton: {
      color: '#5368F5',
      height: '40px',
    },
    toggleDeferButton: {
      color: '#5368F5',
      height: '40px',
      padding: '0px 30px',
    },
    deferralSelect: {
      marginTop: '19px',
    },
    notesTextInput: {
      marginTop: '18px',
    },
    buttonContainer: {
      alignSelf: 'center',
      textAlign: 'center',
    },
    confirmButton: {
      marginTop: '18px',
      backgroundColor: '#5368F5',
      height: '40px',
    },
  },
  { name: 'claim-notes-tab' }
);

export { claimPumpkinNowDecisionStyle };
