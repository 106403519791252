import { useState } from 'react';
import { useCreate, useNotify } from 'react-admin';
import PropTypes from 'prop-types';

import RoutePaths from '../../../../routes';
import AddIncidentModal from './add-incident-modal';

AddIncidentModalController.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  disableButton: PropTypes.bool,
  coverageStartDate: PropTypes.string,
  accidentCoverageStartDate: PropTypes.string,
  wellnessCoverageStartDate: PropTypes.string,
  subDiagnosisData: PropTypes.array,
  refetchIncidentHistory: PropTypes.func.isRequired,
  petId: PropTypes.string.isRequired,
};

export default function AddIncidentModalController({
  onClose,
  open,
  disableButton,
  coverageStartDate,
  accidentCoverageStartDate,
  wellnessCoverageStartDate,
  subDiagnosisData = [],
  refetchIncidentHistory,
  petId,
}) {
  const [create] = useCreate();
  const [isSaving, setIsSaving] = useState(false);
  const notify = useNotify();

  function addIncident(incident) {
    setIsSaving(true);
    const createIncidentApi = `${RoutePaths.pets}/${incident.pet_id}/incident-v2`;
    create(
      createIncidentApi,
      {
        data: {
          type: incident.incident_type,
          original_symptom_date: incident.symptom_date,
          end_date: incident.end_date,
          diagnosis_id: incident.diagnosis,
          sub_diagnosis_id: incident.subdiagnosis,
          notes: incident.notes,
          is_curable: incident.is_curable,
          pre_existing_condition: incident.pre_existing_condition,
          mark_as_cured: incident.is_curable ? incident.mark_as_cured : false,
          latest_occurrence_date: incident.latest_occurrence_date,
        },
      },
      {
        onSuccess: () => {
          setIsSaving(false);
          refetchIncidentHistory();
          onClose();
        },
        onError: error => {
          setIsSaving(false);
          notify(`There was an error while adding an incident: ${error.message}`, {
            type: 'warning',
          });
          onClose();
        },
      }
    );
  }

  return (
    <AddIncidentModal
      open={open}
      onClose={onClose}
      isSaving={isSaving}
      onAddIncident={addIncident}
      disableButton={disableButton}
      subDiagnosisData={subDiagnosisData}
      coverageStartDate={coverageStartDate}
      accidentCoverageStartDate={accidentCoverageStartDate}
      wellnessCoverageStartDate={wellnessCoverageStartDate}
      petId={petId}
    />
  );
}
