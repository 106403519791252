import { useState } from 'react';
import { useCreate, useNotify } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import RoutePaths from '../../../../routes/index.js';
import {
  MedicalRecordsHistoryTypeEnum,
  MedicalRecordsReviewHistoryActionEnum,
  MedicalRecordsReviewHistoryCreatedByEnum,
  PetMrrStateEnum,
} from '../../constants';
import { medicalRecordsReviewHistoryStyle } from './medical-records-review-history-modal-style.js';

function MedicalRecordsReviewHistoryModal({
  petId,
  currentOpsUserId,
  onClose,
  onSuccess,
  currentState = '',
  claimSubmissionId = '',
}) {
  const classes = medicalRecordsReviewHistoryStyle();
  const notify = useNotify();
  const [create] = useCreate();

  const createMrrHistoryApi = `${RoutePaths.pets}/${petId}/mrr-history`;
  const choices = PetMrrStateEnum.choices();

  const [selectedState, setSelectedState] = useState(currentState);
  const [note, setNote] = useState('');

  function handleSubmit() {
    const payload = {
      pet_id: petId,
      type: MedicalRecordsHistoryTypeEnum.MRR_STATE,
      new_mrr_state: selectedState,
      created_by: MedicalRecordsReviewHistoryCreatedByEnum.OPS_USER,
      action: MedicalRecordsReviewHistoryActionEnum.UPDATED,
      ops_user_id: currentOpsUserId,
    };
    if (currentState) payload.previous_mrr_state = currentState;
    if (note) payload.note = note;
    if (claimSubmissionId) payload.claim_submission_id = claimSubmissionId;

    create(
      createMrrHistoryApi,
      { data: payload },
      {
        onSuccess: mmrHIstoryResponseValue => {
          notify('New MRR State created sucessfully.', { type: 'success' });
          onSuccess(mmrHIstoryResponseValue);
          onClose();
        },
        onError: ({ message }) => {
          notify(
            message || `There was an error while creating a new MRR State.`,
            'error'
          );
        },
      }
    );
  }

  return (
    <Dialog open onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle variant='h6' className={classes.modalTitle}>
        MRR Update
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        <FormControl fullWidth variant='outlined' margin='normal'>
          <InputLabel id='status-label'>Update Pet MRR State</InputLabel>
          <Select
            labelId='status-label'
            value={selectedState}
            onChange={e => setSelectedState(e.target.value)}
            label='Update Pet MRR State'
          >
            {choices.map(choice => (
              <MenuItem key={choice.id} value={choice.id}>
                {choice.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          classes={{ root: classes.textLabel }}
          control={
            <TextField
              id='reason-for-request'
              value={note}
              onChange={e => setNote(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              minRows={2}
              multiline
              fullWidth
              placeholder='Add Note (Optional)'
            />
          }
          style={{ marginTop: '16px' }}
        />
      </DialogContent>

      <DialogActions
        classes={{
          root: classes.modalActions,
          spacing: classes.unsetSpacing,
        }}
      >
        <Button
          className={classes.confirmButton}
          variant='contained'
          color='primary'
          onClick={handleSubmit}
        >
          Update
        </Button>
        <Button
          onClick={onClose}
          className={classes.cancelButton}
          variant='outlined'
          color='primary'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MedicalRecordsReviewHistoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentState: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  currentOpsUserId: PropTypes.string,
  petId: PropTypes.string,
  claimSubmissionId: PropTypes.string,
};

export default MedicalRecordsReviewHistoryModal;
