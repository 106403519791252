const MedicalRecordsHistoryTypeEnum = {
  CLINIC_HISTORY: 'clinic_history',
  MRR_STATE: 'mrr_state',
};

const MedicalRecordsReviewHistoryCreatedByEnum = {
  VET: 'Vet',
  CUSTOMER: 'Customer',
  SYSTEM: 'System',
  OPS_USER: 'Ops User',
};

const MedicalRecordsReviewHistoryActionEnum = {
  CREATED: 'Created',
  UPDATED: 'Updated',
  DELETED: 'Deleted',
};

const PetMrrStateEnum = {
  NEEDS_REVIEW: { id: 'needs_review', name: 'Needs Review' },
  PARTIAL_HISTORY_REVIEWED: {
    id: 'partial_history_review',
    name: 'Partial History Reviewed',
  },
  FULL_HISTORY_REVIEWED: {
    id: 'full_history_review',
    name: 'Full History Reviewed',
  },
  MR_REVIEW_NOT_NEEDED: { id: 'mr_review_not_needed', name: 'MR Review Not Needed' },

  choices() {
    return Object.values(this).filter(value => typeof value === 'object');
  },

  getById(id) {
    return Object.values(this).find(state => state.id === id) || null;
  },
};

export {
  PetMrrStateEnum,
  MedicalRecordsHistoryTypeEnum,
  MedicalRecordsReviewHistoryCreatedByEnum,
  MedicalRecordsReviewHistoryActionEnum,
};
