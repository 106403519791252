export const MY_VET_CLINIC = 'MY_VET_CLINIC';
export const OTHER = 'OTHER';

export const HDYHAU_OPTIONS_LEGACY = [
  { value: MY_VET_CLINIC, label: 'My Vet Clinic' },
  { value: 'MY_EMPLOYER', label: 'My Employer' },
  { value: 'BREEDER_OR_SHELTER', label: 'Breeder/Shelter' },
  { value: 'GOOGLE_OR_AD', label: 'Google/Search Ad' },
  { value: 'SOCIAL_MEDIA', label: 'Social Media' },
  { value: 'TELEVISION', label: 'Television' },
  { value: 'FRIEND_OR_FAMILY', label: 'Friend/Family' },
  { value: 'SNAIL_MAIL', label: 'Snail Mail' },
  { value: 'PET_INSURANCE_REVIEW_SITE', label: 'Pet Insurance Review Site' },
  { value: OTHER, label: 'Other' },
];

export const HDYHAU_OPTIONS = [
  { value: MY_VET_CLINIC, label: 'My Vet or Clinic Staff' },
  { value: 'CUSTOMER_REVIEWS', label: 'Reading Customer Reviews' },
  { value: 'PET_INSURANCE_REVIEW_SITE', label: 'Pet Insurance Comparison Site' },
  { value: 'GOOGLE_OR_AD', label: 'Online Search for Insurance' },
  { value: 'TELEVISION', label: 'TV or Streaming' },
  { value: 'FRIEND_OR_FAMILY', label: 'Friend or Family' },
  { value: 'BREEDER', label: 'Breeder' },
  { value: 'SHELTER', label: 'Shelter' },
  { value: 'SOCIAL_MEDIA', label: 'Social Media' },
  { value: 'MY_EMPLOYER', label: 'My Employer' },
  { value: OTHER, label: 'Other' },
];
