import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const MEDICAL_RECORDS_REQUEST_PROVIDER_ERROR = new Error(
  'The hook must be used within a MedicalRecordsRequestProvider'
);

const IsModalOpen = createContext();
const INITIAL_MODAL_STATE = {
  isNewRequest: false,
  viewDetailsId: '',
  markReceivedId: '',
};

function MedicalRecordsRequestProvider({ children }) {
  const [modalType, setModalType] = useState(INITIAL_MODAL_STATE);

  const value = useMemo(() => {
    return {
      modalType,
      setModalType,
    };
  }, [modalType]);

  return <IsModalOpen.Provider value={value}>{children}</IsModalOpen.Provider>;
}

MedicalRecordsRequestProvider.propTypes = {
  children: PropTypes.node,
};

export default MedicalRecordsRequestProvider;

export function useMedicalRecordsRequestModal() {
  const context = useContext(IsModalOpen);

  if (context === undefined) {
    throw MEDICAL_RECORDS_REQUEST_PROVIDER_ERROR;
  }

  const { modalType, setModalType } = context;

  const closeRecordsModal = useCallback(() => {
    setModalType(INITIAL_MODAL_STATE);
  }, []);

  const openNewRequest = useCallback(() => {
    setModalType({ ...INITIAL_MODAL_STATE, isNewRequest: true });
  }, []);

  const openViewDetails = useCallback(viewDetailsId => {
    setModalType({ ...INITIAL_MODAL_STATE, viewDetailsId });
  }, []);

  const openMarkReceived = useCallback(markReceivedId => {
    setModalType({ ...INITIAL_MODAL_STATE, markReceivedId });
  }, []);

  return {
    ...modalType,
    closeRecordsModal,
    openNewRequest,
    openViewDetails,
    openMarkReceived,
  };
}
