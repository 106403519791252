import { useEffect, useState } from 'react';
import {
  ArrayField,
  Confirm,
  Datagrid,
  FunctionField,
  TextField,
  useDelete,
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { useDiagnosis, useSubDiagnosis } from '@pumpkincare/claims';
import { AddIncidentModalController, EditIncidentModal } from '@pumpkincare/shared';
import { usePetMrrHistory } from '@pumpkincare/users';

import useBooleanInput from '../../../hooks/useBooleanInput';
import { getTimeDifferenceFromCurrentDate } from '../../../shared/utils';
import MedicalRequestRecordsInfoBanner from '../../medical-records/view/medical-request-records-info-banner';
import { updateIncident } from '../service/claimService';
import {
  claimIncidentHistoryClasses,
  claimIncidentHistoryStyle,
} from './claim-incident-history-style';

export default function ClaimIncidentHistory(props) {
  const {
    incidentHistoryData,
    refetchIncidentHistory,
    disableDeletion,
    coverageStartDate,
    accidentCoverageStartDate,
    wellnessCoverageStartDate = '',
    petId,
    isSaving = false,
    toggleSaving = () => {},
    shouldDisplayTitle = true,
    currentOpsUserId = '',
    claimSubmissionId = '',
  } = props;

  const classes = claimIncidentHistoryClasses();
  const notify = useNotify();
  const [update] = useUpdate();
  const [deleteOne] = useDelete();
  const refresh = useRefresh();
  const [expandRow, setExpandRow] = useState([]);
  const [incidentHistoryRecord, setIncidentHistoryRecord] = useState();
  const [isAddIncidentModalOpen, toggleAddIncidentModal] = useBooleanInput();
  const [isEditIncidentModalOpen, toggleEditIncidentModal] = useBooleanInput();
  const [
    isDeleteIncidentConfirmationModalOpen,
    toggleDeleteIncidentConfirmationModalOpen,
  ] = useBooleanInput();
  const { data: subDiagnosisData } = useSubDiagnosis();
  const { data: diagnosisData } = useDiagnosis();

  const { fer5442CasAutomatedIncidentCreation, mvx227MrrHistoryBannerAndModal } =
    useFlags();

  const [latestMrrHistory, setLatestMrrHistory] = useState({});

  const { isLoading: isMrrHistoryLoading } = usePetMrrHistory(petId, {
    onSuccess: data => {
      if (!latestMrrHistory?.new_mrr_state && data?.length) {
        setLatestMrrHistory(data[0]);
      }
    },
  });

  useEffect(() => {
    for (let i = 0; i < incidentHistoryData.length; i++) {
      setExpandRow(prevState => [
        ...prevState,
        { incident_number: incidentHistoryData[i].incident_number, open: true },
      ]);
    }
  }, [incidentHistoryData]);

  function onExpandAllRows() {
    const row = expandRow.map(row => {
      return { ...row, open: true };
    });
    setExpandRow(row);
  }

  function onCollapseAllRows() {
    const row = expandRow.map(row => {
      return { ...row, open: false };
    });
    setExpandRow(row);
  }

  function handleDeleteIncident(incident) {
    setIncidentHistoryRecord(incident);
    toggleDeleteIncidentConfirmationModalOpen();
  }

  function handleEditIncident(incidentId, incident) {
    setIncidentHistoryRecord(incident);
    toggleEditIncidentModal();
  }

  function editIncident(incident) {
    updateIncident(
      toggleSaving,
      update,
      notify,
      incident,
      toggleEditIncidentModal,
      refetchIncidentHistory
    );
  }

  function handleConfirmIncidentDeletion() {
    toggleSaving();
    deleteOne(
      'pets',
      {
        id: `${incidentHistoryRecord?.pet_id}/incident/${incidentHistoryRecord?.id}`,
        previousData: incidentHistoryRecord,
      },
      {
        onSuccess: () => {
          toggleSaving();
          toggleDeleteIncidentConfirmationModalOpen();
          refresh();
        },
        onError: error => {
          toggleSaving();
          notify(`There was an error while deleting an incident: ${error.message}`, {
            type: 'error',
          });
        },
      }
    );
  }

  return (
    <>
      {shouldDisplayTitle ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '79%',
            }}
          >
            <Typography
              sx={claimIncidentHistoryStyle.title}
              variant='h6'
              color='primary'
            >
              Incident History
            </Typography>
          </div>
        </div>
      ) : null}
      {!isMrrHistoryLoading && mvx227MrrHistoryBannerAndModal ? (
        <MedicalRequestRecordsInfoBanner
          state={latestMrrHistory?.new_mrr_state}
          date={latestMrrHistory?.created_at}
          initiator={
            latestMrrHistory?.created_by == 'Ops User' && latestMrrHistory?.ops_user
              ? latestMrrHistory?.ops_user.name
              : latestMrrHistory?.created_by
          }
          petId={petId}
          currentOpsUserId={currentOpsUserId}
          onMrrHistoryUpdate={value => setLatestMrrHistory(value)}
          claimSubmissionId={claimSubmissionId}
        />
      ) : null}
      <ArrayField
        source='incidents'
        record={{
          incidents: incidentHistoryData,
        }}
      >
        <Paper>
          <Datagrid
            sx={claimIncidentHistoryStyle.dataGrid}
            bulkActionButtons={false}
            rowClick={(id, basePath, record) => handleEditIncident(id, record)}
          >
            <FunctionField
              label='Diagnosis'
              source='diagnosis'
              sortable={false}
              render={(record = {}) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <FunctionField
                        render={record => (
                          <Typography variant='subtitle2'>
                            {record?.sub_diagnosis?.name}
                          </Typography>
                        )}
                      />

                      <div>
                        <Typography variant='caption' color='primary'>
                          {record.type == 'accident'
                            ? 'Accident'
                            : record.type == 'illness'
                              ? 'Illness'
                              : 'Wellness'}{' '}
                          &nbsp;
                        </Typography>
                        <span style={{ fontSize: '12px', color: '#6B717E' }}>
                          {record?.diagnosis?.name}
                        </span>
                      </div>
                    </div>
                    {record.pre_existing_condition && (
                      <Chip
                        label='PRE-X'
                        size='small'
                        sx={{
                          color: 'white',
                          backgroundColor: '#C62828',
                          fontWeight: '700',
                          width: '48px',
                          height: '22px',
                          fontSize: '10px',
                          marginTop: '4px',
                        }}
                      />
                    )}
                  </div>
                );
              }}
            />

            <TextField
              sx={claimIncidentHistoryStyle.onSetDate}
              label='Onset'
              source='original_symptom_date'
              sortBy='original_symptom_date_as_date'
            />

            <FunctionField
              label='Latest Occ.'
              source='latest_occurrence_date'
              sortable={false}
              render={(record = {}) => {
                return (
                  <>
                    <TextField width='65px' source='latest_occurrence_date' />
                    <div>
                      {record.is_curable === 'curable' && record.end_date ? (
                        <span style={{ fontSize: '12px', color: '#6B717E' }}>
                          Cured
                        </span>
                      ) : record.is_curable === 'curable' ? (
                        <>
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#6B717E',
                            }}
                          >
                            Curable &nbsp;
                          </span>

                          <span
                            style={{
                              fontSize: '12px',
                              color: '#EF6C00',
                              fontWeight: '500',
                            }}
                          >
                            (
                            {getTimeDifferenceFromCurrentDate(
                              record.latest_occurrence_date,
                              'days'
                            )}
                            d)
                          </span>
                        </>
                      ) : record.is_curable === 'incurable' ? (
                        <span style={{ fontSize: '12px', color: '#6B717E' }}>
                          Incurable
                        </span>
                      ) : (
                        <span style={{ fontSize: '12px', color: '#6B717E' }}>
                          Unknown
                        </span>
                      )}
                    </div>
                  </>
                );
              }}
            />

            <FunctionField
              label='Medical Record Reference / Notes'
              source='notes'
              sortable={false}
              sx={{ position: 'relative', minWidth: '50px' }}
              render={(record = {}) => {
                const isOpen = expandRow.filter(obj => {
                  return obj.incident_number === record.incident_number;
                })[0]?.open;
                const lineBreaks = record?.notes?.split('\n');

                return (
                  <Collapse
                    in={isOpen}
                    collapsedSize={44}
                    sx={{
                      display: 'flex',
                      '& .MuiCollapse-wrapperInner': {
                        display: 'flex',
                      },
                    }}
                  >
                    {!isOpen &&
                    (lineBreaks?.length > 1 || record?.notes?.length > 90) ? (
                      <MoreHorizIcon
                        sx={{ opacity: '56%', position: 'absolute', top: '68%' }}
                      />
                    ) : null}

                    <TextField
                      sx={{
                        width: '300px',
                        wordWrap: 'break-word',
                        whiteSpace: 'break-spaces',
                        paddingLeft: '4px',
                      }}
                      source='notes'
                    />
                  </Collapse>
                );
              }}
            />

            <FunctionField
              sortable={false}
              render={(record = {}) => {
                const isCreatedByAutomation =
                  fer5442CasAutomatedIncidentCreation &&
                  record.origin === 'claims_automation';

                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isCreatedByAutomation ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            width: 'max-content',
                            color: '#f57c00',
                            marginRight: '5px',
                          }}
                        >
                          AUTOMATION CREATED
                        </Typography>
                        <InfoIcon fontSize='14' color='warning' />
                      </div>
                    ) : null}
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();

                        const row = expandRow.map(row =>
                          row.incident_number == record.incident_number
                            ? { ...row, open: !row.open }
                            : row
                        );
                        setExpandRow(row);
                      }}
                      sx={claimIncidentHistoryStyle.expandIcon}
                      disabled={isSaving}
                      size='small'
                    >
                      {expandRow.filter(obj => {
                        return obj.incident_number === record.incident_number;
                      })[0]?.open ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                  </div>
                );
              }}
            />
            <FunctionField
              sortable={false}
              render={(record = {}) => {
                return (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      handleDeleteIncident(record);
                    }}
                    sx={claimIncidentHistoryStyle.deleteIcon}
                    data-testid='delete-incident-button'
                    disabled={disableDeletion}
                    size='small'
                  >
                    <DeleteIcon />
                  </IconButton>
                );
              }}
            />
          </Datagrid>
        </Paper>
      </ArrayField>
      <div className={classes.bottomButtons}>
        <Button
          variant='outlined'
          startIcon={<AddIcon />}
          disabled={isSaving}
          className={classes.newIncident}
          onClick={() => toggleAddIncidentModal(petId)}
        >
          NEW INCIDENT
        </Button>
        <div className={classes.rightPosition}>
          <ButtonGroup color='primary' orientation='horizontal' variant='text'>
            <Button
              variant='text'
              color='primary'
              size='small'
              onClick={onExpandAllRows}
            >
              EXPAND ALL
            </Button>

            <Button
              variant='text'
              color='primary'
              size='small'
              onClick={onCollapseAllRows}
            >
              COLLAPSE ALL
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <EditIncidentModal
        open={isEditIncidentModalOpen}
        onClose={toggleEditIncidentModal}
        incident={incidentHistoryRecord}
        coverageStartDate={coverageStartDate}
        accidentCoverageStartDate={accidentCoverageStartDate}
        wellnessCoverageStartDate={wellnessCoverageStartDate}
        onEditIncident={editIncident}
        subDiagnosisData={subDiagnosisData}
        disableButton={isSaving}
        diagnosisData={diagnosisData}
        petId={petId}
      />
      {isAddIncidentModalOpen ? (
        <AddIncidentModalController
          open={true}
          onClose={toggleAddIncidentModal}
          disableButton={isSaving}
          subDiagnosisData={subDiagnosisData}
          coverageStartDate={coverageStartDate}
          accidentCoverageStartDate={accidentCoverageStartDate}
          wellnessCoverageStartDate={wellnessCoverageStartDate}
          refetchIncidentHistory={refetchIncidentHistory}
          petId={petId}
        />
      ) : null}
      <Confirm
        isOpen={isDeleteIncidentConfirmationModalOpen}
        title='Are you sure you want to delete this incident?'
        content=''
        loading={isSaving}
        onConfirm={handleConfirmIncidentDeletion}
        onClose={toggleDeleteIncidentConfirmationModalOpen}
      />
    </>
  );
}

ClaimIncidentHistory.propTypes = {
  incidentHistoryData: PropTypes.array,
  refetchIncidentHistory: PropTypes.func,
  disableDeletion: PropTypes.bool,
  wellnessCoverageStartDate: PropTypes.string,
  petId: PropTypes.string,
  isSaving: PropTypes.bool,
  toggleSaving: PropTypes.func,
  shouldDisplayTitle: PropTypes.bool,
  coverageStartDate: PropTypes.string,
  accidentCoverageStartDate: PropTypes.string,
  currentOpsUserId: PropTypes.string,
  claimSubmissionId: PropTypes.string,
};
