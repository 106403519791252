import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  AutocompleteInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  TextInput,
} from 'react-admin';
import { Link, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, TextField, Typography } from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import {
  ChainIcon,
  COUNTRIES_LIST,
  ProcessingStep,
  ToggleButtonInput,
} from '@pumpkincare/shared';
import { useCheckEmail } from '@pumpkincare/users';

import { stateList } from '../../../../../lib/shared';
import RoutePaths from '../../../../../routes';
import OutlinedVetInput from '../../../../../shared/components/outlined-vet-input';
import PlanSummary from '../../../../plans/plan-summary';
// really need to fix circular dependencies
import {
  HDYHAU_OPTIONS_LEGACY,
  MY_VET_CLINIC,
} from '../../../../quotes/quoteFlow/constants/HDYHAUQuestions';
import { countryToFlag } from '../../../../quotes/quoteFlow/utils/quoteUtils';
import AddressDetails from '../../../../quotes/quoteFlow/view/AddressDetails';

const styleOverwrite = {
  base: {
    fontSize: '16px',
    fontFamily: 'Roboto',
    color: 'rgba(0, 0, 0, 0.87)',
  },
};
const StripeInput = forwardRef((props, ref) => {
  const { component: Component, ...rest } = props;

  const focusCallback = useCallback(
    () => ({
      focus: () => ref.current.focus,
    }),
    []
  );
  const onComponentReady = useCallback(element => (ref.current = element), []);

  useImperativeHandle(ref, focusCallback);

  return (
    <Component
      disableLink
      onReady={onComponentReady}
      options={{ placeholder: '', style: styleOverwrite }}
      {...rest}
    />
  );
});

const INITIAL_STRIPE_STATE = {
  empty: true,
  error: {},
  complete: false,
};

function effectStripeState(element, key, setStripeState) {
  element.on('change', event => {
    setStripeState(prevState => ({
      ...prevState,
      [key]: {
        empty: event.empty,
        error: event.error,
        complete: event.complete,
      },
    }));
  });
}

function CountryOption(country) {
  return (
    <>
      <span style={{ marginRight: '4px' }}>{countryToFlag(country.id)}</span>
      {country.name}
    </>
  );
}

function WellnessQuoteTabCheckout({ quote, selectedPlans }) {
  const elements = useElements();
  const cardNumberElement = elements.getElement(CardNumberElement);
  const cardExpiryElement = elements.getElement(CardExpiryElement);
  const cardCvcElement = elements.getElement(CardCvcElement);

  const [stripeState, setStripeState] = useState({
    cardNumber: INITIAL_STRIPE_STATE,
    cardExpiry: INITIAL_STRIPE_STATE,
    cardCvc: INITIAL_STRIPE_STATE,
  });

  useEffect(() => {
    if (cardNumberElement && cardExpiryElement && cardCvcElement) {
      [
        { key: 'cardNumber', element: cardNumberElement },
        { key: 'cardExpiry', element: cardExpiryElement },
        { key: 'cardCvc', element: cardCvcElement },
      ].forEach(({ key, element }) =>
        effectStripeState(element, key, setStripeState)
      );
    }
  }, [cardNumberElement, cardExpiryElement, cardCvcElement]);

  const { state: routerState } = useLocation();
  const { isAddAPet, user } = useCheckEmail();

  return (
    <div
      style={{
        background: 'white',
        padding: '40px 8px',
        width: '100%',
      }}
    >
      <ProcessingStep
        steps={['Details', 'Choose Plan', 'Checkout']}
        currentStep={3}
        style={{
          width: '63vw',
          minWidth: '500px',
          margin: '0 auto 40px',
        }}
      />

      <FormDataConsumer>
        {({ formData }) => {
          return (
            <Grid container justifyContent='space-between'>
              <Grid item xs={12} sm={7}>
                {isAddAPet ? (
                  <>
                    <Typography variant='body1'>
                      Here is the existing address and payment method associated with
                      this account.
                    </Typography>
                    <Typography variant='body1' sx={{ color: '#EA0404' }}>
                      If you would like to make changes, note that this will update
                      for all pet plans.
                    </Typography>

                    <Button
                      component={Link}
                      to={{
                        pathname: `/${RoutePaths.customers}/${user.id}/show`,
                        state: routerState,
                      }}
                      sx={{ color: '#5868F5', padding: 0, marginBottom: '50px' }}
                    >
                      Edit Details
                    </Button>

                    <AddressDetails existingUserDetails={user} />
                  </>
                ) : (
                  <>
                    <Typography variant='h5' sx={{ marginBottom: '28px' }}>
                      Shipping Address
                    </Typography>

                    <Grid
                      container
                      justifyContent='space-between'
                      sx={{ gap: '8px 24px' }}
                    >
                      <TextInput
                        source='first_name'
                        label='First Name'
                        variant='outlined'
                        size='medium'
                        disabled
                        sx={{ width: '50%', flex: 1 }}
                      />

                      <TextInput
                        source='last_name'
                        label='Last Name'
                        variant='outlined'
                        size='medium'
                        disabled
                        sx={{ width: '50%', flex: 1 }}
                      />

                      <TextInput
                        source='shipping_information.street_1'
                        label='Address'
                        variant='outlined'
                        size='medium'
                        validate={[required()]}
                        sx={{ flex: '100%' }}
                      />

                      <TextInput
                        source='shipping_information.street_2'
                        label='Address Line 2 (Optional)'
                        variant='outlined'
                        size='medium'
                        sx={{ flex: '100%' }}
                        defaultValue=''
                      />

                      <TextInput
                        source='shipping_information.city'
                        label='City'
                        variant='outlined'
                        size='medium'
                        validate={[required()]}
                        sx={{ flex: '100%' }}
                      />

                      <SelectInput
                        source='shipping_information.state'
                        label='State'
                        variant='outlined'
                        size='medium'
                        validate={[required()]}
                        choices={stateList}
                        sx={{ width: '50%', flex: 1 }}
                      />

                      <TextInput
                        source='shipping_information.zipcode'
                        label='Zip Code'
                        variant='outlined'
                        size='medium'
                        validate={[
                          required(),
                          regex(/^\d{5}$/, 'Must be a valid Zip Code'),
                        ]}
                        sx={{ width: '50%', flex: 1 }}
                      />
                    </Grid>

                    <Typography variant='h5' sx={{ marginBottom: '28px' }}>
                      Contact Information
                    </Typography>

                    <Grid
                      container
                      justifyContent='space-between'
                      sx={{ gap: '8px 24px' }}
                    >
                      <TextInput
                        source='email'
                        label='Email Address'
                        variant='outlined'
                        size='medium'
                        sx={{ flex: '100%' }}
                        disabled
                      />

                      <TextInput
                        source='phone'
                        label='Phone Number'
                        variant='outlined'
                        size='medium'
                        sx={{ flex: '100%' }}
                        validate={[required()]}
                      />
                    </Grid>

                    <Typography variant='h5' sx={{ marginBottom: '28px' }}>
                      Payment Information
                    </Typography>

                    <Grid
                      container
                      justifyContent='space-between'
                      sx={{ gap: '8px 24px' }}
                    >
                      <TextInput
                        source='name_on_card'
                        label='Name on Card'
                        variant='outlined'
                        validate={[required()]}
                        size='medium'
                        sx={{ flex: '100%' }}
                      />

                      <TextField
                        required
                        label='Card Number'
                        variant='outlined'
                        size='medium'
                        sx={{ flex: '100%' }}
                        InputLabelProps={{
                          shrink: !stripeState.cardNumber.empty ? true : undefined,
                        }}
                        InputProps={{
                          inputComponent: StripeInput,
                          inputProps: { component: CardNumberElement },
                        }}
                        error={!!stripeState.cardNumber.error?.message}
                        helperText={stripeState.cardNumber.error?.message || ' '}
                      />

                      <TextField
                        required
                        label='Expiration Date (MM/YY)'
                        variant='outlined'
                        size='medium'
                        sx={{ width: '50%', flex: 1 }}
                        InputLabelProps={{
                          shrink: !stripeState.cardExpiry.empty ? true : undefined,
                        }}
                        InputProps={{
                          inputComponent: StripeInput,
                          inputProps: { component: CardExpiryElement },
                        }}
                        error={!!stripeState.cardExpiry.error?.message}
                        helperText={stripeState.cardExpiry.error?.message || ' '}
                      />

                      <TextField
                        required
                        label='CVC'
                        variant='outlined'
                        size='medium'
                        sx={{ width: '50%', flex: 1 }}
                        InputLabelProps={{
                          shrink: !stripeState.cardCvc.empty ? true : undefined,
                        }}
                        InputProps={{
                          inputComponent: StripeInput,
                          inputProps: { component: CardCvcElement },
                        }}
                        error={!!stripeState.cardCvc.error?.message}
                        helperText={stripeState.cardCvc.error?.message || ' '}
                      />
                    </Grid>

                    <Typography variant='subtitle1' sx={{ marginBottom: '8px' }}>
                      Billing Address *
                    </Typography>

                    <RadioButtonGroupInput
                      source='isBillingSameAsShipping'
                      sx={{
                        '& .MuiFormLabel-root': { display: 'none' },
                      }}
                      aria-label='Is the billing address the same as shipping address'
                      row={false}
                      choices={[
                        { id: 'true', name: 'Same as shipping address' },
                        { id: 'false', name: 'Use a different billing address' },
                      ]}
                      validate={[required()]}
                    />

                    {formData.isBillingSameAsShipping === 'false' ? (
                      <Grid container gap='8px 24px'>
                        <AutocompleteInput
                          source={'billing_information.country'}
                          label='Country'
                          variant='outlined'
                          size='medium'
                          sx={{ flex: '100%' }}
                          choices={COUNTRIES_LIST}
                          validate={[required()]}
                          inputText={country => country.name}
                          optionText={CountryOption}
                          optionValue='id'
                        />

                        <TextInput
                          source='billing_information.street_1'
                          label='Address'
                          variant='outlined'
                          size='medium'
                          validate={[required()]}
                          sx={{ flex: '100%' }}
                        />

                        <TextInput
                          source='billing_information.street_2'
                          label='Address Line 2 (Optional)'
                          variant='outlined'
                          size='medium'
                          defaultValue=''
                          sx={{ flex: '100%' }}
                        />

                        <TextInput
                          source='billing_information.city'
                          label='City'
                          variant='outlined'
                          size='medium'
                          validate={[required()]}
                          sx={{ flex: '100%' }}
                        />

                        {formData.billing_information?.country === 'US' ? (
                          <SelectInput
                            source='billing_information.state'
                            label='State'
                            variant='outlined'
                            size='medium'
                            validate={[required()]}
                            choices={stateList}
                            sx={{ width: '50%', flex: 1 }}
                          />
                        ) : (
                          <TextInput
                            source='billing_information.state'
                            label='State'
                            variant='outlined'
                            size='medium'
                            validate={[required()]}
                            sx={{ width: '50%', flex: 1 }}
                          />
                        )}

                        <TextInput
                          source='billing_information.zipcode'
                          label='Zip Code'
                          variant='outlined'
                          size='medium'
                          validate={[required()]}
                          sx={{ width: '50%', flex: 1 }}
                        />
                      </Grid>
                    ) : null}

                    <Typography variant='h5' sx={{ marginBottom: '28px' }}>
                      Referral
                    </Typography>

                    <Grid container gap='8px 24px'>
                      <SelectInput
                        source='referral'
                        choices={HDYHAU_OPTIONS_LEGACY}
                        optionText='label'
                        optionValue='value'
                        variant='outlined'
                        size='medium'
                        sx={{ flex: '100%' }}
                        validate={required()}
                        label='How did you hear about Pumpkin Wellness Club?'
                      />

                      {formData.referral === MY_VET_CLINIC ? (
                        <OutlinedVetInput
                          source='vet'
                          formData={formData}
                          zipcode={formData.zipcode}
                          validate={required()}
                        />
                      ) : null}
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Grid container gap='0 16px' justifyContent='space-between'>
                  <Button sx={{ color: '#000000' }}>QUOTE SUMMARY</Button>

                  <Button sx={{ color: '#5868F5', gap: '0 8px' }}>
                    <ChainIcon />
                    COPY QUOTE
                  </Button>

                  <Button
                    component={Link}
                    to={{
                      pathname: `/${RoutePaths.wellnessQuote}/create`,
                    }}
                    sx={{ color: '#5868F5', gap: '0 8px' }}
                  >
                    <EditIcon />
                    EDIT
                  </Button>
                </Grid>

                <ToggleButtonInput
                  inputOptions={{
                    source: 'billed_annually',
                    validate: [required()],
                    disabled: isAddAPet,
                  }}
                  toggleOptions={[
                    { value: 'false', label: 'MONTHLY' },
                    { value: 'true', label: 'ANNUAL' },
                  ]}
                  sx={{
                    group: {
                      marginTop: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                />

                <PlanSummary
                  pets={(quote.quote_pets || []).map(pet => ({
                    ...pet,
                    wellnessPlan: selectedPlans[pet.id],
                  }))}
                  isChargedAnnually={
                    user?.is_charged_annually || formData.billed_annually === 'true'
                  }
                  hasFeeShown
                  sx={{ width: '100%' }}
                />

                <SaveButton
                  label='CHECKOUT'
                  variant='contained'
                  color='primary'
                  icon={null}
                  sx={{ width: '100%' }}
                  type='button'
                />
              </Grid>
            </Grid>
          );
        }}
      </FormDataConsumer>
    </div>
  );
}

StripeInput.propTypes = {
  component: PropTypes.func.isRequired,
};
CountryOption.propTypes = {
  country: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

WellnessQuoteTabCheckout.propTypes = {
  quote: PropTypes.shape({
    id: PropTypes.string,
    quote_pets: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  selectedPlans: PropTypes.object.isRequired,
};

export default WellnessQuoteTabCheckout;
