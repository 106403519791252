import {
  Button,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { Button as MuiButton, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

import DateFieldIgnoringZone from '../../../../components/DateFieldIgnoringZone';
import RoutePaths from '../../../../routes';
import { parseDeductible } from '../../../../shared/utils/productUtils';
import { AuditLogsLink } from '../../../audit-logs';
import FieldStatusChip from '../field-status-chip';
import PendingPolicyChangeDetail from '../pending-policy-change-detail';
import petShowStyle from '../pet-show-style';
import PolicyExpandableDatagrid from '../policy-expandable-datagrid';
function isRowExpandable(row) {
  return row?.changes_history.length > 0;
}

function parseToPolicyChanges(record) {
  if (!record) {
    return [];
  }

  return record.changes_history.map(item => {
    const changes = item.changes ? JSON.parse(item.changes) : null;
    return {
      args: JSON.parse(item.args),
      id: item?.id,
      change_type: item?.change_type,
      multiple_pet_discount_percentage: record?.multiple_pet_discount_percentage,
      group_discount_percentage: record?.group_discount_percentage,
      status: item.processed_at ? 'updated' : 'pending',
      processed_at: item?.processed_at,
      diff: changes,
    };
  });
}

function CustomPolicyDatagridHeader({ children }) {
  const classes = petShowStyle();

  const borderTopLeftRadius = { borderTopLeftRadius: '4px' };
  const borderTopRightRadius = { borderTopRightRadius: '4px' };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.colHeader} style={borderTopLeftRadius}>
          {''}
        </TableCell>
        {children.map((child, idx, arr) => {
          return (
            <TableCell
              key={(child.props.label || child.props.source) + '_' + idx}
              className={classes.colHeader}
              style={arr.length - 1 === idx ? borderTopRightRadius : {}}
            >
              {child.props.label || child.props.source}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function PolicyDatagrid(props) {
  const classes = petShowStyle();

  const { onPetCancelClick, onCreateDocsClick, ...rest } = props;
  const { fer5659ExamDayDiscount } = useFlags();

  function formatDiscount(amount, percentage) {
    if (amount === 0) {
      return '0 (0%)';
    }

    return `${amount} (${100 - percentage * 100}%)`;
  }

  function PolicyTitle() {
    return (
      <div className={classes.spacing}>
        <Typography className={classes.title}>Policies</Typography>
      </div>
    );
  }

  return (
    <>
      <PolicyTitle />

      <SimpleShowLayout className={classes.root} {...rest}>
        <ReferenceManyField
          label={''}
          reference={RoutePaths.policies}
          target='pet_id'
          sort={{ field: 'created_at', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
            header={<CustomPolicyDatagridHeader />}
            data-id='policy-datagrid'
            expand={({ record }) => {
              const changes = parseToPolicyChanges(record);
              const pendingPolicyChanges = changes.filter(
                ({ status }) => status == 'pending'
              );
              const processedPolicyChanges = changes.filter(
                ({ status }) => status != 'pending'
              );
              const pendingPolicyChangesDetails = pendingPolicyChanges.map(
                pendingPolicyChange => (
                  <PendingPolicyChangeDetail
                    key={pendingPolicyChange.id}
                    pendingPolicyChange={pendingPolicyChange}
                  />
                )
              );

              return (
                <div>
                  {pendingPolicyChangesDetails}
                  <PolicyExpandableDatagrid policyChanges={processedPolicyChanges} />
                </div>
              );
            }}
            isRowExpandable={isRowExpandable}
          >
            <FunctionField
              label='Year'
              render={(record = {}) => record.year?.toString().padStart(4, '0')}
              headerClassName={classes.colHeader}
            />

            <FieldStatusChip source='status' headerClassName={classes.colHeader} />

            <FunctionField
              label='Deductible'
              render={(record = {}) => parseDeductible(record.deductible)}
              headerClassName={classes.colHeader}
            />
            <TextField
              label='annual limit'
              source='annual_limit'
              headerClassName={classes.colHeader}
            />
            <TextField source='coinsurance' headerClassName={classes.colHeader} />
            <TextField source='premium' headerClassName={classes.colHeader} />
            <FunctionField
              label='Effective Date'
              render={(record = {}) => {
                return (
                  <DateFieldIgnoringZone
                    source='policy_effective_date'
                    record={record}
                  />
                );
              }}
              headerClassName={classes.colHeader}
            />
            <FunctionField
              label='End Date'
              render={(record = {}) => {
                return (
                  <DateFieldIgnoringZone source='policy_end_date' record={record} />
                );
              }}
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label='Accident Cov. Start Date'
              render={(record = {}) => {
                return (
                  <DateFieldIgnoringZone
                    source='policy_accident_coverage_start_date'
                    record={record}
                  />
                );
              }}
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label='Illness Cov. Start Date'
              render={(record = {}) => {
                return (
                  <DateFieldIgnoringZone
                    source='policy_illness_coverage_start_date'
                    record={record}
                  />
                );
              }}
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label='Is whitelabel?'
              render={(record = {}) => (record.is_whitelabel ? 'yes' : 'no')}
              headerClassName={classes.colHeader}
            />
            <TextField
              label='Program ID'
              source='program_id'
              headerClassName={classes.colHeader}
            />
            <FunctionField
              label='Is annual?'
              render={(record = {}) => (record.is_annualized_policy ? 'yes' : 'no')}
              headerClassName={classes.colHeader}
            />
            <TextField
              label='change type'
              source='change_type'
              headerClassName={classes.colHeader}
            />
            <TextField
              label='Raw Rate'
              source='raw_rate'
              headerClassName={classes.colHeader}
            />
            <FunctionField
              label='Multi-Pet Discount'
              render={(record = {}) =>
                formatDiscount(
                  record.multiple_pet_discount,
                  record.multiple_pet_discount_percentage
                )
              }
              headerClassName={classes.colHeader}
            />
            <FunctionField
              label={fer5659ExamDayDiscount ? 'Discount' : 'Group Discount'}
              render={(record = {}) =>
                formatDiscount(
                  record.group_discount,
                  record.group_discount_percentage
                )
              }
              headerClassName={classes.colHeader}
            />
            <TextField
              label='Filed Rate'
              source='filed_rate'
              headerClassName={classes.colHeader}
            />
            <TextField
              label='Capped Rate'
              source='capped_rate'
              headerClassName={classes.colHeader}
            />
            <FunctionField
              label=''
              render={(record = {}) => {
                return [
                  'expired',
                  'voided',
                  'declined',
                  'cancelled',
                  'cancel_pending',
                ].some(status => status === record.status) ||
                  !record.policy_effective_date ? null : (
                  <MuiButton
                    color='secondary'
                    variant='outlined'
                    style={{ textTransform: 'none' }}
                    onClick={() => onCreateDocsClick(record.id)}
                  >
                    CREATE DOCS
                  </MuiButton>
                );
              }}
              headerClassName={classes.colHeader}
            />

            <FunctionField
              label=''
              render={(record = {}) => {
                return <AuditLogsLink filter={{ policy_id: record.id }} />;
              }}
              headerClassName={classes.colHeader}
            />
            <FunctionField
              label=''
              render={(record = {}) => {
                if (record.status === 'renew_pending') {
                  return (
                    <Button
                      style={{
                        color: '#D4424E',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                      }}
                      variant='outlined'
                      onClick={() => onPetCancelClick(record)}
                      label='DECLINE'
                    />
                  );
                } else {
                  return null;
                }
              }}
              headerClassName={classes.colHeader}
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </>
  );
}
export default PolicyDatagrid;
