import { useState } from 'react';
import { Create, useCreate, useNotify, useRedirect, useRefresh } from 'react-admin';
import { useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { GENDER_MAP, useProgramResolver } from '@pumpkincare/shared';
import { usePetPolicies } from '@pumpkincare/users';

import useBooleanInput from '../../../hooks/useBooleanInput';
import { isCreditCardExpDateValid } from '../../../shared/utils';
import { createUserOnboardingTasks } from '../../customers/service/customerService';
import PlanSummary from '../../plans/plan-summary';
import InsurancePicker from '../insurance-picker';
import AddPaymentMethodModal from './add-payment-method-modal';

function InsuranceCreate() {
  const location = useLocation();
  const { punks1954UpsellSawToSawIns, mvx104McOnboardingModal } = useFlags();
  const { pet, user } = location?.state || {};

  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const [create, { isLoading: isCreateLoading }] = useCreate();
  const [isAddPaymentModalOpen, toggleIsAddPaymentMethodModalOpen] =
    useBooleanInput(false);
  const [billingInformation, setBillingInformation] = useState({});

  const {
    isLoading: isProgramResolverLoading,
    data: { rating_version: ratingVersion },
  } = useProgramResolver(
    user.mailing_address.state_province,
    pet?.species,
    pet?.age
  );

  const [currentInsurance, setCurrentInsurance] = useState({});

  const { breed_name: breedName, age, gender, species, name } = pet || {};

  const { data: petPoliciesData } = usePetPolicies(pet.id);

  const showAddPaymentMethodButton =
    punks1954UpsellSawToSawIns &&
    !user.payment_details &&
    !Object.keys(billingInformation).length > 0;

  function handleAddProduct() {
    const { deductible, annual_limit, coinsurance, premium } = currentInsurance;
    const { id: petId } = pet;
    const {
      payment_details,
      first_name,
      billing_address: userBillingAddress,
      id: userId,
    } = user;

    const payload = {
      deductible,
      annual_limit,
      coinsurance,
      premium,
      pet_id: petId,
      program_id: ratingVersion,
    };

    if (payment_details && !isProgramResolverLoading) {
      const { exp_month, exp_year } = payment_details;
      if (!isCreditCardExpDateValid(exp_month, exp_year)) {
        notify('Expired payment method. Please add a new one', 'warning');
        return;
      }

      payload.billing_information = {
        name_on_card: first_name,
        city: userBillingAddress.city,
        state: userBillingAddress.state_province,
        zipcode: userBillingAddress.zipcode,
        street_1: userBillingAddress.street_1,
        street_2: userBillingAddress.street_2 || '',
        country: userBillingAddress.country,
      };
    } else if (Object.keys(billingInformation).length) {
      payload.billing_information = billingInformation;
    } else {
      notify('Please add a payment method', 'warning');
      return;
    }

    create(
      `policies/add-insurance`,
      { data: payload },
      {
        onSuccess: () => {
          if (mvx104McOnboardingModal)
            createUserOnboardingTasks(userId).catch(() => {});
          redirect(`/customers/${userId}/show`);
        },
        onError: error => {
          const errorMessage =
            error?.body?.message ||
            'An error occurred while adding the insurance policy.';
          notify(`Error: ${errorMessage}`, 'error');
        },
      }
    );
  }

  function handleAddInsurance(insurance) {
    const { rate, ...rest } = insurance;

    setCurrentInsurance({ premium: rate, ...rest });
  }

  function handleAddCard() {
    toggleIsAddPaymentMethodModalOpen();
  }

  function handleOnSubmit(billingInfo) {
    setBillingInformation(billingInfo);
    refresh();
  }

  return (
    <Create title={'Add Insurance'}>
      <div>
        <IconButton
          onClick={() => redirect(`/customers/${user.id}/show`)}
          size='large'
          sx={{ display: 'flex', color: '#007DFF' }}
        >
          <ArrowBackIosIcon />
          <Typography
            sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: '500' }}
          >
            Add Insurance
          </Typography>
        </IconButton>
      </div>
      <div style={{ width: '100%' }}>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={2}
          sx={{ padding: '16px 24px' }}
        >
          <Grid item>
            <Container
              sx={{
                border: '1px solid #0000001F',
                borderRadius: '8px',
                padding: '24px 16px !important',
                minWidth: '708px',
              }}
            >
              <Typography variant='h5' color='standard' sx={{ marginBottom: '8px' }}>
                {name}
              </Typography>

              <Typography variant='standard' sx={{ textTransform: 'capitalize' }}>
                {` ${species} • ${breedName} • Age ${age} • ${
                  GENDER_MAP[gender] || 'N/A'
                }`}
              </Typography>

              <Divider sx={{ marginTop: '24px' }} />

              {petPoliciesData ? (
                <InsurancePicker
                  onChange={handleAddInsurance}
                  onRemove={() => setCurrentInsurance({})}
                  policiesData={petPoliciesData}
                />
              ) : (
                <CircularProgress
                  size={32}
                  thickness={4}
                  sx={{ marginTop: '24px', display: 'absolute' }}
                />
              )}
            </Container>
          </Grid>

          <Grid item>
            <Typography
              variant='h6'
              sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: '500' }}
            >
              Summary
            </Typography>

            <PlanSummary
              pets={[
                {
                  ...pet,
                  policy: currentInsurance,
                },
              ]}
              hasFeeShown
              isAddingInsurance
              isChargedAnnually={user.is_charged_annually}
            />
            {showAddPaymentMethodButton ? (
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  borderRadius: '8px',
                  padding: '16px !important',
                  backgroundColor: '#EF6C000A',
                }}
              >
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <InfoOutlinedIcon
                    sx={{
                      lineHeight: '20px',
                      fontSize: '18px',
                      fontWeight: '500',
                      color: '#D32F2F',
                      marginRight: '8px',
                    }}
                    fontSize='inherit'
                  />
                  <Typography
                    variant='h6'
                    sx={{ fontSize: '14px', color: '#D32F2F' }}
                  >
                    No payment method on file
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={handleAddCard}
                >
                  <Typography
                    variant='h6'
                    sx={{ fontSize: '13px', color: '#D32F2F' }}
                  >
                    ADD CARD
                  </Typography>
                  <ChevronRightIcon sx={{ color: '#D32F2F' }} />
                </Grid>
              </Grid>
            ) : null}

            <Button
              sx={{
                width: '100%',
                marginTop: '16px',
                height: '40px',
              }}
              variant={'contained'}
              disabled={
                !currentInsurance.premium ||
                isCreateLoading ||
                showAddPaymentMethodButton
              }
              onClick={handleAddProduct}
            >
              Add Product
            </Button>
          </Grid>
        </Grid>
      </div>

      {isAddPaymentModalOpen && (
        <AddPaymentMethodModal
          onClose={toggleIsAddPaymentMethodModalOpen}
          onSubmit={handleOnSubmit}
        />
      )}
    </Create>
  );
}

export default InsuranceCreate;
